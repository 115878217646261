import React from 'react';
import TeamMembers from '../data/teamMembers';
import { team } from '../routes'
import { Panel, PanelContent, ImagePanel } from '../components/Panels'
import { H1, P } from '../components/common/text';
import { AvatarList } from '../components/cards';
import { Page } from '../components';
import { TeamMobile } from '../img/headers';
import { ScreenSizes } from '../components/context/ScreenSizeContext';
import { withResponsive, hasMobile } from '../components/hoc';

export const Team = () => {
    const HeaderImage = withResponsive(ScreenSizes.TABLET)(hasMobile(() => (<ImagePanel image={TeamMobile} />))(() => (<ImagePanel image={team.headingImage} />)));

    return (
        <Page>
            <HeaderImage />
            <Panel>
                <PanelContent>
                    <H1>Who are we?</H1>
                    <P>HomeMetrics is a prelaunched startup company, founded in Rapid City, SD, that manufactures IOT sensor networks, which monitor the health of a home by gathering data on its water and electrical grids. Machine learning and artificial intelligence allow this data allow for proactive, predictive and preventative maintenance. Not only will our system prevent damage saving the property owner money, but it will allow them to focus on enjoying life rather than worrying about the health of their properties.</P>
                    <P>Over the past year and a half, a few levels of customer discovery have been finished and multiple stages of prototypes have been designed/built to prove technological hypotheses. Now over the next two months, HomeMetrics will install the MetricsNetwork into five homes as part of a pilot testing program in order to find any bugs within our software platform and hardware. Over the following six months, we expect to reach product market fit and achieve our first 10 sales.</P>
                </PanelContent>
            </Panel>
            <Panel>
                <AvatarList data={TeamMembers} />
            </Panel>
        </Page>
    )

}


export default Team;