import Home from './pages/Home';
import Houses from './pages/Houses'
import HowItWorks from './pages/Works';
import Team from './pages/Team';
import Support from './pages/Support';
import UserSignup from './pages/SignUp';
import Package from './package';
import Devices from './devices/device';
import Products from './pages/Products';

import {
    Home as HomeHeader,
    Works as WorksHeader,
    Team as TeamHeader,
    Support as SupportHeader,
} from './img/headers';

export const home = {
    path: '/',
    component: Home,
    exact: true,
    headingImage: HomeHeader
};

export const houses = {
    path: '/houses',
    component: Houses,
    exact: false,
    headingImage: null
}

export const works = {
    isHidden: true,
    path: '/how-it-works',
    component: HowItWorks,
    exact: true,
    headingImage: WorksHeader,
};

export const team = {
    path: '/team',
    component: Team,
    exact: true,
    headingImage: TeamHeader,
};

export const support = {
    path: '/support',
    component: Support,
    exact: true,
    headingImage: SupportHeader,

}

export const signUp = {
    path: '/signup',
    component: UserSignup,
    exact: true,
    headingImage: null,
}

export const packages = {
    path: '/user/package',
    component: Package,
    exact: true,
    headingImage: null,
}

export const device = {
    path: '/devices',
    component: Devices,
    exact: true,
    headingImage: null,
}

export const products = {
    path: '/products',
    component: Products,
    exact: true,
    headingImage: null
}

export default [
    home,
    houses,
    works,
    team,
    support,
    signUp,
    packages,
    device,
    products
]