import React from 'react';

import { Header } from './components';
import { Footer } from './components/Footer';

import { HashRouter } from "react-router-dom";
import { Routes } from './components';

import ScreenSizeContext, { getScreenSize } from './components/context/ScreenSizeContext';
import { Theme } from './components/context/ThemeContext';
import { ThemeProvider } from 'styled-components';

require('dotenv').config();

const globalWrapStyle = { minHeight: '100vh', display: 'flex', flexDirection: 'column', position: 'relative' };

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenSize: getScreenSize(window.innerWidth),
    }

    window.onresize = this.handleResize.bind(this);
  }


  handleResize = () => {
    const screenSize = getScreenSize(window.innerWidth);

    if (screenSize !== this.state.screenSize) {
      this.setState({ screenSize: screenSize });
    }

  }

  render() {
    document.title = 'HomeMetrics';
    return (
      <HashRouter className='Site'>
        <ScreenSizeContext.Provider value={this.state.screenSize}>
          <ThemeProvider theme={Theme}>
            <div style={globalWrapStyle}>
              <Header />
              <Routes />
              <Footer />
            </div>
          </ThemeProvider>
        </ScreenSizeContext.Provider>
      </HashRouter >
    );

  }
}

export default App;
