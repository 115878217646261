export const mapsDiagram = {
    source: require('./Maps-Diagram.png'),
    alt: 'A neighborhood of HomeMetrics sensor protected houses',
};

export const homeDetailed = {
    source: require('./Home-Detailed-Compressed.jpg'),
    alt: 'An image of a modern home',
};

export const landingPageVideo = [{
    source: require('./landing_page_video_480p.mp4'),
    alt: 'A video',
    type: 'video/mp4'
},
{
    source: require('./landing_page_video_720p.mp4'),
    alt: 'A video',
    type: 'video/mp4'
},
{
    source: require('./landing_page_video_1080p.mp4'),
    alt: 'A video',
    type: 'video/mp4'
},
{
    source: require('./landing_page_video_2k.mp4'),
    alt: 'A video',
    type: 'video/mp4'
},
{
    source: require('./landing_page_video_4k.mp4'),
    alt: 'A video',
    type: 'video/mp4'
}];