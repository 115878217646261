import React from 'react';
import { home } from '../routes'
import { Page } from '../components';
import { HeroImagePanel } from '../components';
import { PanelContent, Panel, Pane, SplitContent, VideoPanel, CardPanel } from '../components/Panels'
import { H1, H2, P } from '../components/common/text'
import { ImageCardList, ImageCard } from '../components/cards';
import { Image } from '../components/common/Image';
import styled from 'styled-components';

import { waterLeakIcon, floodingIcon, waterUsageIcon, electricityUsageIcon, clock, money, stress, ecoMode, away } from '../img/icons';
import { homeDetailed, landingPageVideo } from '../img/banner'

const getVideoSrc = width => {
    if (width >= 4000) return 4;
    if (width >= 2000) return 3;
    if (width >= 1000) return 2;
    if (width >= 700) return 1;
    return 0;
};

const cards = [
    {
        id: 0,
        title: 'Water Leaks',
        image: waterLeakIcon,
        body: 'Water leaks are detected within minutes via leak detection sensors ensuring water damage does not build up over time.'
    },
    {
        id: 1,
        title: 'Electrical Usage',
        image: electricityUsageIcon,
        body: 'Electricity usage is tracked via electrical meters which get installed in a properties electrical box, optimizing your energy usage.'
    },
    {
        id: 2,
        title: 'Floods',
        image: floodingIcon,
        body: 'Floods and broken pipes are identified preventing any water damage from propagating, followed by an automated water-shut off valve kicking in.'
    },
    {
        id: 3,
        title: 'Water Usage',
        image: waterUsageIcon,
        body: 'Water usage is monitored helping you save on your utilitiy bills, in addition to providing you with info on your daily water habits'
    }
]

const StyledPane = styled(Pane)`
    padding: 0 2em;
    display: flex;
    width: 45%;
    min-width: 360px;
    align-items: center;
`

const ImagePane = styled(StyledPane)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
`;

const StyledImage = styled(Image)`
    width: 8em;
    height: 8em;
    margin: 0.5em;
`

const QuoteBody = styled(P)`
    color: ${props => props.theme.lightBase};
    font-size: 1.5em;
`

const QuoteAuthor = styled(P)`
    text-align: right;
    color: ${props => props.theme.lightBase};
    margin-right: 4em;
`

const BigImageCard = styled(ImageCard)`
    width: 400px;
`

class Home extends React.Component {

    render() {
        return (
            <Page>
                <HeroImagePanel heroImage={home.headingImage}>
                    <PanelContent>
                        <H1 style={{ textAlign: 'left' }}>Home Health Monitoring</H1>
                        <H2>Predictive, Preventative, Proactive</H2>
                    </PanelContent>
                </HeroImagePanel>

                <Panel>
                    <SplitContent>
                        <StyledPane>
                            <P>HomeMetrics is the proactive home health monitoring solution
                            for predicting and preventing water damage on your properties.
                            Install out smart home sensor network and recieve 24/7 monitoring
                            of your home's plumbing grid. Saving you money, saving you time,
                            and most importantly, allowing you to focus on enjoying your life.
                            </P>
                        </StyledPane>
                        <ImagePane>
                            <div>
                                <StyledImage {...clock} />
                                <StyledImage {...money} />
                            </div>
                            <StyledImage {...stress} />
                        </ImagePane>
                    </SplitContent>
                </Panel>

                <VideoPanel {...landingPageVideo[getVideoSrc(window.innerWidth)]} />

                <Panel>
                    <ImageCardList data={cards} />
                </Panel>

                <HeroImagePanel heroImage={homeDetailed}>
                    <PanelContent>
                        <QuoteBody>"The MetricsNetwork helps me save up to 3000 gallons of water a year!</QuoteBody>
                        <QuoteAuthor><i> - Matter Irwin, Homeowner</i></QuoteAuthor>
                    </PanelContent>
                </HeroImagePanel>

                <Panel>
                    <CardPanel>
                        <BigImageCard
                            title={'Eco Mode'}
                            image={ecoMode}
                            body={
                                `Eco mode is a feature that can be turned on with the MetricsNetwork, in which it regulates your property's water flow rate and pressure ensuring that you save water, helping the environment, and thus saving money. In addition, the Eco Mode decreases the amount of strain on water pipes, increasing the life of your plumbing system.`
                            }
                        />
                        <BigImageCard
                            title={'Away Mode'}
                            image={away}
                            body={
                                `Away mode is a feature which can be enabled when you leave your property or home unattended. While away, we will be monitoring your home, and if we notice any unusual activity on your water or energy, we will take action: shutting off your home's water, preventing water damage from ever occuring.`
                            }
                        />
                    </CardPanel>

                </Panel>
            </Page>
        )

    }
}



export default Home;
