import React from 'react';
import { connect } from 'react-redux';


import './package.css';
import './generals.css';

import Button from 'react-bootstrap/Button'

class Package extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOption: 1
        };
    }

    handleRadioClick = (event) => {
        this.setState({
            selectedOption: parseInt(event.target.value)
        });
    }

    handleClick = (item)=>{
        alert("You selected package: " + item.title);

        this.props.history.push('/user/congrats');
    }

    render(){
        let itemNames = this.props.items.map(item=>{
            return(
                <div className="package-item">
                    <label>
                        <input
                            type="radio"
                            name="package"
                            value={item.id}
                            checked={this.state.selectedOption === item.id}
                            onClick={this.handleRadioClick} />
                        {item.title}
                    </label>
                </div>
            )
        })

        let itemDetail = this.props.items.map(item=>{
            if (item.id === this.state.selectedOption) {
                return (
                    <div className="card" key={item.id}>
                        <div className="card-title">
                            <span className="card-title"><h2>{item.title}</h2></span>
                        </div>
                        <div className="card-pair">
                            <div className="card-image">
                                <img className="card-image" src={item.img} alt={item.title}/>
                            </div>
                            <div className="card-content">
                                <p><ul>{item.features.split(";").map(feature=>{
                                    return(
                                        <li>{feature}</li>
                                    )
                                })}</ul></p>
                            </div>
                        </div>
                        <div className="card-price">
                            <p>Price: ${item.price}</p>
                        </div>
                        <Button onClick={()=>{this.handleClick(item)}}>Confirm</Button>
                    </div>
                )
            }
            return("")
        })

        return(
        <div className='products center m-centerHorizontally'>
            <div className="item-select">
                <h2>Please Select a Package:</h2>
                {itemNames}
            </div>
            <div>{itemDetail}</div>
        </div>
        )

    }
}

const mapStateToProps = (state)=>{
    return {
        items: state.items
    }
}

export default connect(mapStateToProps)(Package)
