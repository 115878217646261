import React from 'react';
import { Image } from './common/Image';
import { H1 } from './common/text';
import { blackIcon } from '../img/logos';
import styled from 'styled-components';

export const Logo = styled(Image)`
    width: 50%;
    height: auto;
`

// export const Header = styled()

export const Unauthenticated = ({ className }) => {
    return (
        <div className={className}>
            <H1>Please sign in to view this content...</H1>
            <Logo {...blackIcon} />
        </div>
    )
}

export default styled(Unauthenticated)`
    text-align: center;
    padding: 3em;
`
