import PhoneTypes from './PhoneTypes'

export default class UserDataModel {
    first_name;
    last_name;
    password;
    email;
    phone;
    phone_type;

    constructor(userViewModel) {
        this.first_name = userViewModel.firstName
        this.last_name = userViewModel.lastName
        this.email = userViewModel.email
        this.phone = userViewModel.phone
        this.phone_type = PhoneTypes.display.findIndex(v => v === userViewModel.phoneType)

        // Password will only ever exist in the instance of creating an account
        this.password = userViewModel.password ? userViewModel.password : ''
    }
}

