import { withCondition } from './withCondition';

export const hasMobile = (Responsive) => WrappedComponent => {
    const HasMobile = props => {
        return withCondition(props.useMobile, Responsive, WrappedComponent)(props);
    };

    HasMobile.displayName = `HasMobile(${WrappedComponent.displayName})`

    return HasMobile;
}

export default hasMobile;