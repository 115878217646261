import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';


export const Base = styled(NavLink)`
    border: none;
    color: ${props => props.theme.darkBase};
    padding: 1em 5em;

    &:hover {
        background-color: ${props => props.theme.darkAccent};
        color: ${props => props.theme.lightAccent};
        text-decoration: none;
    }

    &.active {
        background-color: ${props => props.theme.darkAccent};
        color: ${props => props.theme.lightBase};
    }
`


export const AccountDrawerButton = ({ children, ...props }) => (
    <Base {...props} >
        {children}
    </Base>
)


export default AccountDrawerButton