import React from 'react';
import PropTypes from 'prop-types';

export const DropDownOption = ({ className, value, display, ...props }) => {
    return (
        <option {...props} className={className} value={value}>{display}</option>
    )
}

export const propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display: PropTypes.string,
}

DropDownOption.propTypes = propTypes;

export default DropDownOption