import { useState } from 'react';

export const useInput = (intialValue, validators = []) => {
    const [value, setValue] = useState(intialValue);
    const [errors, setErrors] = useState([]);

    const getErrors = (value) => {

        const errors = validators.map(validator => validator(value)).filter(error => error);
        return errors;
    }

    const handleChange = (value) => {
        setErrors(getErrors(value));
        setValue(value);
    }

    const validate = () => {
        const newErrors = getErrors(value);
        setErrors(newErrors);
        return newErrors.length === 0
    }

    return [value, handleChange, errors, validate];
}

export default useInput;