import React from 'react';
import styled from 'styled-components';
import BaseInput from './BaseInput';
import { LabelText, P } from '../text';


const Input = styled(BaseInput)`
    border: none;
    border-bottom: solid #000 3px;
    padding: 0;
    margin-bottom: 1rem;
`;

export const EditableValue = ({ className, value, onValueChange, isEditing, label }) => {

    if (!isEditing) {
        return (
            <div className={className}>
                <LabelText>{label}</LabelText>
                <P>{value}</P>
            </div>
        )
    } else {
        return (
            <div className={className}>
                <LabelText>{label}</LabelText>
                <Input value={value} onValueChange={onValueChange} />
            </div>
        )
    }
}


export default styled(EditableValue)``;