import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PrimaryDrawer from './PrimaryDrawer';

const Overlay = styled.div`
    display: ${props => props.isOpen ? "block" : "none"};
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
`

export const ModalDrawer = ({ isOpen, ...props }) => {

    return (
        <>
            <PrimaryDrawer isOpen={isOpen} {...props} />
            <Overlay isOpen={isOpen} />
        </>
    );
}

export const propTypes = {
    isOpen: PropTypes.bool,
}

export const defaultProps = {
    isOpen: false,
}

ModalDrawer.propTypes = propTypes;
ModalDrawer.defaultProps = defaultProps;

export default memo(ModalDrawer);