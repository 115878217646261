import React, { useState } from 'react';
import { IconButton } from '../common/Buttons';
import styled from 'styled-components';
import { AccountIcon } from '../../img/icons';
import AccountDrawer from './AccountDrawer';
import { hasAuth } from '../hoc';

export const Drawer = styled(AccountDrawer)``;

export const AccountButton = ({ className }) => {
    const [drawerOpen, setDrawer] = useState(false);


    return (
        <>
            <IconButton Icon={AccountIcon} onClick={() => { setDrawer(true) }} />
            <Drawer isOpen={drawerOpen} onClose={() => { setDrawer(false) }} />
        </>
    )
}


export default styled(hasAuth(true)(AccountButton))``;