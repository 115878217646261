import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base/Base';
import styled from 'styled-components';

export const TransparentButton = ({ children, ...props }) => {
    return (
        <Base {...props}>
            {children}
        </Base>
    )
}

const propTypes = {
    children: PropTypes.element,
}

TransparentButton.propTypes = propTypes;

export default styled(TransparentButton)`
    background: none;
    border: none;
`;