import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Panel } from './Panels';

const Filter = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
`

const StyledPanel = styled(Panel)`
    background: url('${props => props.source}') no-repeat center;
    background-size: cover;
    padding: 172px 0px;
    position: relative;
    color: #fff;
    text-shadow: 1px 1px 2px black;
    text-align: left;
`

const ChildrenWrapper = styled.div`
    position: relative;
    color: ${props => props.theme.lightBase};
`

export const HeroImagePanel = ({ heroImage, children }) => {
    return (
        <StyledPanel source={heroImage.source}>
            <Filter />
            <ChildrenWrapper>
                {children}
            </ChildrenWrapper>
        </StyledPanel>
    );
}

const propTypes = {
    style: PropTypes.object,
    heroImage: PropTypes.shape({ source: PropTypes.string.isRequired }).isRequired,
    children: PropTypes.element,
}

HeroImagePanel.propTypes = propTypes;

export default memo(HeroImagePanel);