import React from 'react';
import PropTypes from 'prop-types';

export const Label = ({ className, label }) => (
    <p className={className}>{label}</p>
)

export const propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
}

Label.propTypes = propTypes;

export default Label
