import React from 'react';
import { support } from '../routes';
import { Page } from '../components';
import { Socials } from '../components';
import { IconButton } from '../components/common/Buttons';
import { EmailIcon } from '../img/icons'
import { PanelContent, Panel } from '../components/Panels'
import { H1, P } from '../components/common/text';
import styled from 'styled-components';

const TitleText = styled(H1)`
    text-align: left;
`

const BodyText = styled(P)`
    color: #fff;
`

const StyledPage = styled(Page)`
    & {
        position: relative;
        padding-top: 8em;
    }

    &::before {
        position: absolute;
        top: 0;
        background: url('${props => props.image.source}');
        background-position: center;
        background-size: cover;
        z-index: -1;
        filter: brightness(0.7);
        left: 0;
        right: 0;
        bottom: 0;
        content: ""
    }
`

const StyledSocials = styled(Socials)`
    & .iconWrapper {
        box-shadow: 2px 2px 1px #111;
    }
`;

const StyledIconButton = styled(IconButton)`
    & .iconWrapper {
        box-shadow: 2px 2px 1px #111;
    }

    & .label {
        text-shadow: 2px 2px 1px #111;
    }
`

const StyledContent = styled(PanelContent)`
    color: #fff;
    text-shadow: 2px 2px 1px #111;
`

export const Support = () => {
    return (
        <StyledPage image={support.headingImage}>
            <Panel>
                <StyledContent>
                    <TitleText>Contact Us</TitleText>
                    <BodyText>Please do not hesitate to reach out to our team here at HomeMetrics if you have any questions, feedback or feature requests.</BodyText>
                    <StyledIconButton
                        isDark={true}
                        Icon={EmailIcon}
                        href={'mailto:contact@home-metrics.com'}
                        label={'contact@home-metrics.com'}
                    />
                    <div>
                        <StyledSocials isDark={true} />
                    </div>
                </StyledContent>
            </Panel>
        </StyledPage >
    )
}
//
export default Support;