import React from 'react';
// Code adapted from https://medium.com/dailyjs/react-composing-higher-order-components-hocs-3a5288e78f55


export const isList = ListWrapper => WrappedComponent => {
    const IsList = ({ data, ...props }) => (
        <ListWrapper>
            {data.map(item => (
                <WrappedComponent {...item} key={item.id} {...props} />
            ))}
        </ListWrapper>
    );

    IsList.displayName = `isList(${WrappedComponent.name})`

    return IsList;
}

export default isList;