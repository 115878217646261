import { hasMobile, isList, withResponsive } from '../../hoc';
import { compose } from 'redux';
import { CardPanel } from '../../Panels';
import MobileProduct from './MobileProduct';
import DesktopProduct from './DesktopProduct';
import { ScreenSizes } from '../../context/ScreenSizeContext';

export default compose(
    isList(CardPanel),
    withResponsive(ScreenSizes.TABLET),
    hasMobile(MobileProduct)
)(DesktopProduct);