
export const PreferencesDataModel = (prefViewModel) => {
    const ret = {
        house_name: prefViewModel.houseName,
        favorite: prefViewModel.isFavorite ? 1 : 0
    }

    return ret;
}


export default PreferencesDataModel
