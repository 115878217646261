import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../hooks/useInput'
import { PasswordInput, TextInput, RadioInput, PhoneInput } from '../common/Inputs';
import { validateMinLength, validateHasDigits, validateHasSpecial, validateEmail, validatePhone, validateRequired } from '../../utils/validators'
import { Form, MultiInput } from '../common/form';

import PhoneTypes from '../../store/user/models/PhoneTypes'
import { createUser, selectStatus, selectError, LoadStates, signIn } from '../../store/user/userReducer'

const phoneOptions = [
    {
        value: PhoneTypes.ANDROID,
        displayText: PhoneTypes.display[PhoneTypes.ANDROID]
    },
    {
        value: PhoneTypes.IOS,
        displayText: PhoneTypes.display[PhoneTypes.IOS]
    }
]

const validators = {
    firstName: [validateRequired],
    lastName: [validateRequired],
    email: [validateEmail, validateRequired],
    phoneNumber: [validatePhone, validateRequired],
    password: [validateMinLength(6), validateHasDigits, validateHasSpecial, validateRequired],
    phoneType: [validateRequired],
}

const CreateUserForm = ({ onSuccess }) => {
    const [firstName, setFirstName, firstNameErrors, validateFirstName] = useInput('', validators.firstName);
    const [lastName, setLastName, lastNameErrors, validateLastName] = useInput('', validators.lastName);
    const [email, setEmail, emailErrors, validateEmail] = useInput('', validators.email);
    const [phoneNumber, setPhoneNumber, phoneNumberErrors, validatePhoneNumber] = useInput('', validators.phoneNumber);
    const [password, setPassword, passwordErrors, validatePassword] = useInput('', validators.password);

    // Password Confirm has a dependency on Password so it must be instantiated after it
    const passwordConfirmValidator = [...validators.password, (value) => (value !== password ? { type: 'match', message: 'Passwords must match' } : null)]

    const [passwordConfirm, setPasswordConfirm, passwordConfirmErrors, validatePasswordConfirm] = useInput('', passwordConfirmValidator);
    const [phoneType, setPhoneType, phoneTypeErrors, validatePhoneType] = useInput(null, validators.phoneType);

    const dispatch = useDispatch()
    const submitStatus = useSelector(selectStatus)
    const submitErrors = useSelector(selectError)

    //TODO: Remove this after moving validation logic to redux
    const [formErrors, setFormErrors] = useState([]);


    useEffect(() => {
        if (submitErrors)
            setFormErrors([{ type: 'submit', message: submitErrors }])
    }, [submitErrors])

    const validateForm = () => {
        let isValid = validateFirstName();
        isValid &= validateLastName();
        isValid &= validateEmail()
        isValid &= validatePhoneNumber()
        isValid &= validatePassword()
        isValid &= validatePasswordConfirm()
        isValid &= validatePhoneType()

        if (!isValid) {
            setFormErrors([{ type: 'errors', message: 'Please fix the errors in the form and resubmit' }]);
            return false;
        }

        return true;
    }

    const submitForm = () => {
        dispatch(createUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phoneNumber,
            password: password,
            phoneType: phoneType
        })).then(dispatch(signIn({ email, password })))
    }

    const handleSubmit = () => {
        if (validateForm())
            submitForm();
    }

    return (
        <Form onSubmit={handleSubmit} errors={formErrors} isLoading={submitStatus === LoadStates.FETCHING}>
            <MultiInput>
                <TextInput
                    label={'First Name *'}
                    errors={firstNameErrors}
                    value={firstName}
                    onValueChange={setFirstName}
                    placeholder={'Enter First Name...'}
                    autoComplete={'name'}
                />
                <TextInput
                    label={'Last Name *'}
                    value={lastName}
                    onValueChange={setLastName}
                    errors={lastNameErrors}
                    placeholder={'Enter Last Name...'}
                    autoComplete={'family-name'}
                />
            </MultiInput>
            <MultiInput>
                <TextInput
                    label={'Email *'}
                    errors={emailErrors}
                    value={email}
                    onValueChange={setEmail}
                    placeholder={'Enter Email...'}
                    autoComplete={'email'}
                />
                <PhoneInput
                    label={'Phone Number *'}
                    value={phoneNumber}
                    errors={phoneNumberErrors}
                    onValueChange={setPhoneNumber}
                    placeholder={'Enter Phone...'}
                    autoComplete={'tel'}
                />
            </MultiInput>
            <MultiInput>
                <PasswordInput
                    label={'Password'}
                    value={password}
                    errors={passwordErrors}
                    onValueChange={setPassword}
                    placeholder={'Enter Password...'}
                    autoComplete={'new-password'}
                />
                <PasswordInput
                    label={'Confirm Password'}
                    value={passwordConfirm}
                    errors={passwordConfirmErrors}
                    onValueChange={setPasswordConfirm}
                    autoComplete={'new-password'}
                    placeholder={'Re-Enter Password...'}
                />
            </MultiInput>
            <RadioInput
                label={'Select your phone type:'}
                value={phoneType}
                errors={phoneTypeErrors}
                onValueChange={setPhoneType}
                options={phoneOptions}
            />
        </Form>
    )
}

export default CreateUserForm;
