import React from 'react';
import { useSelector } from 'react-redux'
import { selectAuth } from '../../store/user/userReducer'
import withCondition from './withCondition'

export const requiresAuth = (DefaultComponent) => (WrappedComponent) => {
    const RequiresAuth = (...props) => {
        const isLoggedIn = useSelector(selectAuth)

        const Component = withCondition(isLoggedIn, WrappedComponent, DefaultComponent)

        return <Component {...props} />;
    }

    RequiresAuth.displayName = `requiresAuth(${WrappedComponent.name})`

    return RequiresAuth
}

export default requiresAuth;