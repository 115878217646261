import React, { useState, memo } from "react";
import { InteractionStatus } from '../../common/InteractionStatus'
import PropTypes from 'prop-types'

export const Button = (props) => {
  const [status, setStatus] = useState(props.status)

  let buttonStyle = { ...props.styleRestful };
  if (status === InteractionStatus.HOVERED) {
    buttonStyle = { ...buttonStyle, ...props.styleHovered };
  }


  return <button
    style={buttonStyle}
    className={props.className}
    onMouseEnter={() => setStatus(InteractionStatus.HOVERED)}
    onMouseLeave={() => setStatus(InteractionStatus.RESTFUL)}
    onClick={props.onClick}
    disabled={props.isDisabled}
  >{props.children}</button>;
};

export const propTypes = {
  styleRestful: PropTypes.object,
  styleHovered: PropTypes.object,
  status: PropTypes.oneOf([InteractionStatus.FOCUSED, InteractionStatus.HOVERED, InteractionStatus.RESTFUL]),
  onClick: PropTypes.func,
}

export const defaultProps = {
  styleRestful: {},
  styleHovered: {},
  status: InteractionStatus.RESTFUL,
  onClick: () => { },
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default memo(Button)