import {
    WaterSensor,
} from '../img/products';


export default [
    {
        id: 0,
        title: 'Water Module',
        description: 'The Water Module monitors the temperature, pressure, and flow rate while also offering the following features:',
        features: [
            'Emergency Notifications for water temperatures below 40 degrees F.',
            'Remote Water Shutoff: Turn off (or turn on) your water from your phone',
            'Eco Mode: Reduce the water usage of your home by an average of 3425 gallons per year!',
        ],
        image: WaterSensor
    },
]