import React, { useContext } from 'react';
import { ScreenSizeContext } from '../context/ScreenSizeContext';

export const withResponsive = responsiveSize => WrappedComponent => {
    const WithResponsive = props => {
        const screenSize = useContext(ScreenSizeContext);
        return <WrappedComponent {...props} useMobile={screenSize <= responsiveSize} />;
    }

    WithResponsive.displayName = `withResponsive(${WrappedComponent.name})`

    return WithResponsive;
}

export default withResponsive