import React from 'react';
import Base from './Base/Base';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const PrimaryButton = ({ className, buttonTitle, children, ...props }) => {
    if (buttonTitle) {
        console.warn('Button Title is being deprecated, pass text as child instead');
    }

    return (
        <Base className={className} {...props}>
            {children ? children : buttonTitle}
        </Base>
    );
}

export const propTypes = {
    buttonTitle: PropTypes.string,
}



PrimaryButton.propTypes = propTypes;

export default styled(PrimaryButton)`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.lightAccent};
    border: solid 2px ${props => props.theme.primary};
    padding: 8px;
    margin: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.25s, color 0.25s;
    

    &:hover {
        background-color: ${props => props.theme.primaryAccent};
        color: ${props => props.theme.primary};
    }
`