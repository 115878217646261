import React from 'react';
import PropTypes from 'prop-types';
import { H2, P } from '../../common/text';
import { Image } from '../../common/Image';
import { IconButton } from '../../common/Buttons';
import styled from 'styled-components';
import { LinkedInIcon } from '../../../img/icons'

const AvatarWrap = styled.div`
    width: 278px;
    text-align: center;
    margin: 32px;
`

const Name = styled(H2)`
    
`

const StyledImage = styled(Image)`
    width: 100%;
    height: auto;
    border-radius: 50%;
`

const ImageWrap = styled.div`
    position: relative;
    padding: 16px;
    border: solid 1px gray;
    border-radius: 50%;
    margin-bottom: 8px;
`

const Title = styled(P)`
    margin-bottom: 0.5rem;
`

export const Avatar = ({ name, image, title, linkedIn }) => {

    return (
        <AvatarWrap>
            <ImageWrap>
                <StyledImage {...image} />
            </ImageWrap>
            {name && <Name>{name}</Name>}
            {title && <Title>{title}</Title>}
            {linkedIn && <IconButton Icon={LinkedInIcon} href={linkedIn} />}
        </AvatarWrap>
    )
}

export const propTypes = {
    image: PropTypes.shape({ source: PropTypes.string.isRequired, alt: PropTypes.string.isRequired }).isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
}

Avatar.propTypes = propTypes;

export default Avatar;