export { ReactComponent as AccountIcon } from './account.svg';
export { ReactComponent as AddIcon } from './add.svg';
export { ReactComponent as CloseIcon } from './close.svg';
export { ReactComponent as DeleteIcon } from './delete.svg';
export { ReactComponent as EditIcon } from './edit.svg';
export { ReactComponent as EmailIcon } from './email.svg';
export { ReactComponent as FacebookIcon } from './facebook.svg';
export { ReactComponent as FavoriteBorderIcon } from './favoriteBorder.svg';
export { ReactComponent as FavoriteIcon } from './favorite.svg';
export { ReactComponent as GroupsIcon } from './groups.svg';
export { ReactComponent as InstagramIcon } from './instagram.svg';
export { ReactComponent as LinkedInIcon } from './linkedIn.svg';
export { ReactComponent as ListIcon } from './list.svg';
export { ReactComponent as MenuIcon } from './menu.svg';
export { ReactComponent as MenuCloseIcon } from './menuClose.svg';
export { ReactComponent as RefreshIcon } from './refresh.svg';
export { ReactComponent as SupportIcon } from './support.svg';
export { ReactComponent as SaveIcon } from './save.svg';
export { ReactComponent as UndoIcon } from './undo.svg';

export const floodingIcon = {
    source: require('./Flooding.jpg'),
    alt: 'House Flooding Icon',
};

export const waterLeakIcon = {
    source: require('./Water-Leak.jpg'),
    alt: 'Water Leaking Icon',
};

export const waterUsageIcon = {
    source: require('./Water-Usage.jpg'),
    alt: 'Water Usage Icon',
};

export const electricityUsageIcon = {
    source: require('./Electricity-Usage.jpg'),
    alt: 'Electricity Usage Icon',
};

export const money = {
    source: require('./Money-Black.png'),
    alt: 'Money',
};

export const stress = {
    source: require('./Stress-Black.png'),
    alt: 'Stress',
};

export const clock = {
    source: require('./Clock-Black.png'),
    alt: 'Time',
};

export const ecoMode = {
    source: require('./EcoMode.png'),
    alt: 'Eco Mode Icon',
};

export const away = {
    source: require('./Away.png'),
    alt: 'Away from home icon',
};

export const email = {
    source: require('./email.svg'),
    alt: 'Email Icon',
};

export const hidden = {
    source: require('./hidden.svg'),
    alt: 'Hidden content',
};

export const visible = {
    source: require('./visible.svg'),
    alt: 'Visible content',
};