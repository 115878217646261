import { isList, withResponsive, hasMobile } from '../hoc';
import { ScreenSizes } from '../context/ScreenSizeContext';
import { MobileNavMenu, DesktopNavMenu } from './';
import { compose } from 'redux';
import { MobileNavButton, DesktopNavButton } from '../NavButtons';

const NavWrapper = compose(
    withResponsive(ScreenSizes.TABLET),
    hasMobile(MobileNavMenu)
)(DesktopNavMenu)

export const NavMenuContainer = compose(
    isList(NavWrapper),
    withResponsive(ScreenSizes.TABLET),
    hasMobile(MobileNavButton)
)(DesktopNavButton)

export default NavMenuContainer;