export const Theme = {
    primary: '#0034C2',
    primaryAccent: '#D6E1FF',
    secondary: 'gray',
    secondaryAccent: 'lightgray',
    lightAccent: '#E2E2E2',
    darkAccent: '#292929',
    lightBase: '#fff',
    darkBase: '#000',
    baseFont: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
    fontColor: '#000',
    paragraphColor: 'gray',
    errorColor: '#ff0000',
}