import React from 'react';
import { NavLink } from 'react-router-dom';
import Base from './Base/Base'
import styled from 'styled-components';

const Navigation = styled(NavLink)`
    background: none;
    color: ${props => props.theme.lightAccent};
    fill: ${props => props.theme.lightAccent};
    padding: 16px;
    width: 286px;
    display: block;
    text-align: left;
    &:hover {
        text-decoration: none;
        color: ${props => props.theme.lightBase};
        fill: ${props => props.theme.lightBase};
    }

    &.active {
        background: ${props => props.theme.lightBase};
        color: ${props => props.theme.darkBase};
        fill: ${props => props.theme.darkBase}
    }
`;

const Button = styled(Base)`
    background: ${props => props.theme.darkBase};
    border: none;
    padding: 0px;
    transition: all 0.125s;

    &:hover {
        background: ${props => props.theme.darkAccent};
        padding: 0px;
        textDecoration: none;
    }
    
    & .icon {
        margin-right: 1em;
    }
`


export const MobileNavButton = ({ Icon, label, href, ...props }) => {
    return (
        <Button {...props} >
            <Navigation to={href} activeClassName='active' >
                <Icon className='icon' />
                {label}
            </Navigation>
        </Button>
    );
}

export default MobileNavButton;