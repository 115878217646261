import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Base from './Base/Base';

const Button = styled(Base)`
    background-color: transparent;
    border: none;
    color: ${props => props.theme.darkAccent};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 8px;

    &.dark {
        color: ${props => props.theme.lightAccent};
    }

    &:hover {
        color: ${props => props.theme.lightAccent};
    }

    &.dark:hover {
        color: ${props => props.theme.darkAccent};
    }
`

export const IconWrapper = styled.div`
    background-color: ${props => props.theme.darkAccent};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    position: relative;
    transition: all 0.125s;

    & .icon {
        height: 18px;
        width: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        fill: ${props => props.theme.lightAccent}
    }

    ${Button}:hover & {
        background-color: ${props => props.theme.lightAccent};
        border: solid 2px ${props => props.theme.darkAccent};

    }

    ${Button}:hover & .icon {
        fill: ${props => props.theme.darkAccent};
    }

    &.dark {
        background-color: ${props => props.theme.lightAccent};
    }

    &.dark .icon {
        fill: ${props => props.theme.darkAccent};
    }

    ${Button}:hover &.dark {
        background-color: ${props => props.theme.darkAccent};
        border-color: ${props => props.theme.lightAccent};

    }

    ${Button}:hover &.dark .icon {
        fill: ${props => props.theme.lightAccent};
    }
`

export const Label = styled.span`
    margin-left: 1em;
`

export const IconButton = ({ className, isDark, label, Icon, ...props }) => {
    return (
        <Button {...props} className={`${className} ${isDark ? 'dark' : ''}`} >
            <IconWrapper className={`iconWrapper ${isDark ? 'dark' : ''}`}>
                <Icon className="icon" />
            </IconWrapper>
            {label && <Label className='label'>{label}</Label>}
        </Button>
    );
}

const propTypes = {
    isDark: PropTypes.bool,
    label: PropTypes.string,
}

const defaultProps = {
    isDark: false,
}

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;