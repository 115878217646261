import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { H2 } from '../text';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`
const animationSpeed = 3;

const OuterRing = styled.div`
    display: inline-block;
    height: 24px;
    width: 24px;
    border: solid 4px gray;
    border-right: solid 4px transparent;
    background: ${props => props.color};
    border-radius: 50%;
    animation: ${rotate} ${animationSpeed}s linear ${props => props.delay}s infinite;
`;

const InnerRing = styled(OuterRing)`
    display: block;
    height: 100%;
    width: 100%;
    animation-speed: ${animationSpeed / 2}s;
`

const Wrapper = styled.div`
    display: inline-block;
`

const Message = styled(H2)`
    color: gray;
`

export const Loader = ({ className, message }) => (
    <Wrapper className={className}>
        { message ? <Message className="Message">{message}</Message> : null}
        <OuterRing className="OuterRing" delay={'0'}>
            <InnerRing className="InnerRing" delay={'0.09'} />
        </OuterRing>
    </Wrapper>
);

const propTypes = {
    message: PropTypes.string,
};

Loader.propTypes = propTypes;

export default Loader;