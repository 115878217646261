import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BaseDrawer = styled.div`
    position: fixed;
    z-index: 6;
    display: flex;
    transition: all 0.25s ease-in;
    flex-direction: column;
    background-color: #000;
    width: 0px;
    max-width: 0px;
    overflow: hidden;

    &.right { 
        top: 0;
        right: 0;
        bottom: 0;
    }

    &.left {
        top: 0;
        left: 0;
        bottom: 0;
    }

    &.open {
        max-width: 80vw;
        width: auto;
    }
`

export const PrimaryDrawer = ({ isOpen, className, side, children, ...props }) => {

    return (
        <>
            <BaseDrawer {...props} className={`${className} ${side} ${isOpen ? "open" : ""}`}>
                {children}
            </BaseDrawer>
        </>
    );
}

export const propTypes = {
    isOpen: PropTypes.bool,
    side: PropTypes.oneOf(['right', 'left'])
}

export const defaultProps = {
    isOpen: false,
    side: 'right',
}

PrimaryDrawer.propTypes = propTypes;
PrimaryDrawer.defaultProps = defaultProps;

export default memo(PrimaryDrawer);