
export const withCondition = (conditional, ComponentOnTrue, ComponentOnFalse) => {
    if (conditional) {
        return ComponentOnTrue;
    } else if (ComponentOnFalse) {
        return ComponentOnFalse;
    } else {
        return null;
    }
};

export default withCondition;