import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { selectJwt } from '../user/userReducer'
import * as houseApi from './housesApi'

export const LoadStates = {
    UNFETCHED: 'unfetched',
    FETCHING: 'fetching',
    IDLE: 'idle',
    UPDATING: 'updating',
    ADDING: 'adding',
    ERROR: 'error'
}


const initialState = {
    data: [],
    house: {
        id: null,
        status: LoadStates.UNFETCHED,
        error: null,
    },
    status: LoadStates.UNFETCHED,
    error: null
}

export const fetchHouse = createAsyncThunk('houses/fetchHouse', async (id, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)
    const response = await houseApi.fetchHouse(jwt, id)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const fetchHouses = createAsyncThunk('houses/fetchHouses', async (_, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)

    const response = await houseApi.fetchHouses(jwt)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const addHouse = createAsyncThunk('houses/addHouse', async (house, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)

    const response = await houseApi.addHouse(jwt, house)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const removeHouse = createAsyncThunk('houses/removeHouse', async (houseId, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)

    const response = await houseApi.removeHouse(jwt, houseId)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const updateHouse = createAsyncThunk('houses/updateHouse', async ({ id, house }, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)

    const response = await houseApi.updateHouse(jwt, id, house)

    if (response.isValid) {
        return { id, house: response.data }
    } else {
        return rejectWithValue(response.message)
    }
})

export const housesSlice = createSlice({
    name: 'houses',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchHouse.pending]: (state, _) => {
            state.house.status = LoadStates.FETCHING
        },
        [fetchHouse.fulfilled]: (state, action) => {
            const id = action.payload.id
            state.data = state.data.filter(h => h.id !== id)
            state.data.push(action.payload)
            state.house.id = id
            state.house.status = LoadStates.IDLE
        },
        [fetchHouse.rejected]: (state, action) => {
            state.house.status = LoadStates.ERROR
            state.house.error = action.payload.message
        },
        [fetchHouses.pending]: (state, _) => {
            state.status = LoadStates.FETCHING
        },
        [fetchHouses.fulfilled]: (state, action) => {
            state.status = LoadStates.IDLE
            state.data = action.payload
        },
        [fetchHouses.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload.message
        },
        [addHouse.pending]: (state, _) => {
            state.status = LoadStates.ADDING
        },
        [addHouse.fulfilled]: (state, action) => {
            state.status = LoadStates.IDLE
            state.data.push(action.payload)
        },
        [addHouse.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload.message
        },
        [removeHouse.pending]: (state, _) => {
            state.status = LoadStates.UPDATING
        },
        [removeHouse.fulfilled]: (state, action) => {
            state.status = LoadStates.IDLE
            state.data = state.data.filter(h => h.id !== action.payload)
        },
        [removeHouse.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload.message
        },
        [updateHouse.pending]: (state, _) => {
            state.status = LoadStates.UPDATING
        },
        [updateHouse.fulfilled]: (state, action) => {
            state.status = LoadStates.IDLE
            state.data = state.data.map(h => (h.id === action.payload.id ? { ...h, ...action.payload.house } : h))
        },
        [updateHouse.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload.message
        }
    }
})

export const selectAllHouses = state => state.houses.data
export const selectedHouse = state => state.houses.data.find(h => h.id === state.houses.house.id)
export const selectHouseId = state => state.houses.house.id
export const selectHouseStatus = state => state.houses.status
export const selectHouseError = state => state.houses.error
export const houseFetchStatus = state => state.houses.house.status

export default housesSlice.reducer