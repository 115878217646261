import React from 'react';
import PropTypes from 'prop-types';

export const BaseInput = ({ name, className, disabled, onBlur, onFocus, placeholder, type, value, onValueChange, ...props }) => {
    return (
        <input
            {...props}
            className={className}
            disabled={disabled}
            onBlur={onBlur}
            type={type}
            onFocus={onFocus}
            value={value}
            onChange={e => onValueChange(e.target.value, name)}
            placeholder={placeholder}
        />
    );
}

export const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
}

export const defaultProps = {
    onBlur: () => { },
    onFocus: () => { },
    placeholder: 'Enter...',
}

BaseInput.propTypes = propTypes;
BaseInput.defaultProps = defaultProps;

export default BaseInput;