import React from 'react';
import styled from 'styled-components';

const ContactText = styled.a`
    color: #fff;
    text-decoration: none;
    font-family: ${props => props.theme.baseFont};

    &:hover {
        color: #fff;
    }
`

export const ContactLink = () => (
    <ContactText href="mailto:contact@home-metrics.com">Contact Us</ContactText>
)

export default ContactLink;