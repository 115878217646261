import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, DropDownInput } from '../common/Inputs'
import { Form } from '../common/form';
import { MultiInput } from '../common/form/multiLineInput';
import { validateMinValue, validateAddress, validateMinLength, validateState, validateZip, validateRequired, validateMaxValue } from '../../utils/validators'
import { useInput } from '../hooks/useInput';
import states from '../../data/states';

const stateOptions = states.map(state => ({ value: state.Code, display: state.State }))

const validators = {
    sqft: [validateMinValue(100), validateRequired],
    residents: [validateMinValue(1), validateRequired],
    baths: [validateMinValue(1), validateRequired],
    beds: [validateMinValue(1), validateRequired],
    year: [validateMinValue(1900), validateMaxValue(new Date().getFullYear()), validateRequired],
    primaryAddress: [validateAddress, validateRequired],
    secondaryAddress: [validateAddress],
    city: [validateMinLength(1), validateRequired],
    state: [validateState, validateRequired],
    zip: [validateZip, validateRequired],
}

const AddHouseForm = ({
    onSubmit,
    isLoading,
    ...props
}) => {
    const [formErrors, setFormErrors] = useState([])
    const [houseName, setHouseName, houseNameErrors] = useInput(props.preferences.houseName)
    const [sqft, setSqft, sqftErrors, validateSqft] = useInput(props.sqft, validators.sqft)
    const [residentCount, setResidentCount, residentCountErrors, validateResidentCount] = useInput(props.residentCount, validators.residents)
    const [bathCount, setBathCount, bathCountErrors, validateBathCount] = useInput(props.bathCount, validators.baths)
    const [bedCount, setBedCount, bedCountErrors, validateBedCount] = useInput(props.bedCount, validators.beds)
    const [year, setYear, yearErrors, validateYear] = useInput(props.year, validators.year)
    const [primaryAddress, setPrimaryAddress, primaryAddressErrors, validatePrimaryAddress] = useInput(props.primaryAddress, validators.primaryAddress)
    const [secondaryAddress, setSecondaryAddress, secondaryAddressErrors, validateSecondaryAddress] = useInput(props.secondaryAddress, validators.secondaryAddress)
    const [city, setCity, cityErrors, validateCity] = useInput(props.city, validators.city)
    const [state, setState, stateErrors, validateState] = useInput(props.state, validators.state)
    const [zipCode, setZipCode, zipCodeErrors, validateZipCode] = useInput(props.zipCode, validators.zip)

    // TODO: Shift primary validation logic out to reducer
    const validateForm = () => {
        let isValid = validateSqft()
        isValid &= validateResidentCount()
        isValid &= validateBathCount()
        isValid &= validateBedCount()
        isValid &= validateYear()
        isValid &= validatePrimaryAddress()

        if (secondaryAddress.length > 0) {
            isValid &= validateSecondaryAddress()
        }

        isValid &= validateCity()
        isValid &= validateState()
        isValid &= validateZipCode()

        if (!isValid) {
            setFormErrors([{ type: 'errors', message: 'Please fix the errors in the form and resubmit' }]);
            return false;
        }

        return true;
    }

    const handleSubmit = () => {
        if (validateForm())
            onSubmit({ id: props.id, sqft, residentCount, bathCount, bedCount, year, primaryAddress, secondaryAddress, city, state, zipCode, preferences: { houseName, isFavorite: props.isFavorite } })
    }

    return (
        <Form onSubmit={handleSubmit} errors={formErrors} isLoading={isLoading}>
            <MultiInput>
                <TextInput
                    label={"House Name"}
                    value={houseName}
                    onValueChange={setHouseName}
                    errors={houseNameErrors}
                    placeholder={"House name..."}
                />
                <TextInput
                    label={'Square Footage (sqft) *'}
                    value={sqft}
                    onValueChange={setSqft}
                    errors={sqftErrors}
                    placeholder={'Property Square Footage...'}
                />
                <TextInput
                    label={'Year *'}
                    value={year}
                    onValueChange={setYear}
                    errors={yearErrors}
                    placeholder={'Property Year Built...'}
                />
            </MultiInput>
            <MultiInput>
                <TextInput
                    label={'Resident Count *'}
                    value={residentCount}
                    onValueChange={setResidentCount}
                    errors={residentCountErrors}
                    placeholder={'Number of Residents...'}
                />
                <TextInput
                    label={'Bath Count *'}
                    value={bathCount}
                    onValueChange={setBathCount}
                    errors={bathCountErrors}
                    placeholder={'Number of Baths...'}
                />
                <TextInput
                    label={'Bedroom Count *'}
                    value={bedCount}
                    onValueChange={setBedCount}
                    errors={bedCountErrors}
                    placeholder={'Number of Bedrooms...'}
                />
            </MultiInput>
            <MultiInput>
                <TextInput
                    label={'Primary Address *'}
                    value={primaryAddress}
                    onValueChange={setPrimaryAddress}
                    errors={primaryAddressErrors}
                    placeholder={'Primary Address...'}
                />
                <TextInput
                    label={'Secondary Address'}
                    value={secondaryAddress}
                    onValueChange={setSecondaryAddress}
                    errors={secondaryAddressErrors}
                    placeholder={'Secondary Address...'}
                />
            </MultiInput>
            <MultiInput>
                <TextInput
                    label={'City *'}
                    value={city}
                    onValueChange={setCity}
                    errors={cityErrors}
                    placeholder={'City...'}
                />
                <DropDownInput
                    label={'State *'}
                    value={state}
                    onValueChange={setState}
                    errors={stateErrors}
                    placeholder={'Select a State...'}
                    options={stateOptions}
                />
                <TextInput
                    label={'Zip Code *'}
                    value={zipCode}
                    onValueChange={setZipCode}
                    errors={zipCodeErrors}
                    placeholder={'5-Digit ZipCode...'}
                />
            </MultiInput>
        </Form >
    )
}

export const propTypes = {
    bathCount: PropTypes.number,
    bedCount: PropTypes.number,
    city: PropTypes.string,
    onSubmit: PropTypes.func,
    primaryAddress: PropTypes.string,
    residentCount: PropTypes.number,
    secondaryAddress: PropTypes.string,
    sqft: PropTypes.number,
    state: PropTypes.string,
    zipCode: PropTypes.number,
    preferences: PropTypes.shape({
        houseName: PropTypes.string,
        isFavorite: PropTypes.bool,
    })
}

export const defaultProps = {
    city: '',
    primaryAddress: '',
    secondaryAddress: '',
    state: '',
    preferences: {
        isFavorite: false,
        houseName: '',
    }
}

AddHouseForm.propTypes = propTypes
AddHouseForm.defaultProps = defaultProps

export default AddHouseForm;