import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label, { propTypes as labelProps } from './Label';
import Errors, { propTypes as errorProps } from './Errors';
import Icon, { propTypes as iconProps } from './Icon';
import BaseInput, { propTypes as baseProps } from './BaseInput';
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
    position: relative;
`;

export const InputIcon = styled(Icon)`
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
    width: 24px;
    filter: opacity(0.5);

    &:hover {
        filter: opacity(1);
        cursor: pointer;
    }
`

export const Input = styled(BaseInput)`
    border: solid 1px gray;
    border-radius: 4px;
    padding: 0.65em;
    outline: none;
    width: 100%;

    &:hover {
        border: solid 1px black};
    }

    ${props => props.focused && css`
        border: solid 1px ${props.theme.primary};

        &:hover {
            border: solid 1px ${props.theme.primary};
        }
    `}

    ${props => props.error && css`
        border: solid 1px ${props.theme.errorColor};

        &:hover {
            border: solid 1px ${props.theme.errorColor};
        }
    `}
`

export const InputLabel = styled(Label)`
    color: gray;
    font-size: 0.75em;
    font-family: ${props => props.theme.baseFont};
    margin: 0;
    text-align: left;
    padding: 0;
`

export const InputErrors = styled(Errors)`
    font-size: 0.75em;
    text-align: left;
    width: fit-content;
`

export const TextInput = ({ errors, className, label, onIconClick, icon, onFocus, onBlur, value, ...props }) => {
    const [isFocused, setFocused] = useState(false);
    // console.log(value);

    return (
        <div className={className}>
            <InputLabel label={label} />
            <InputWrapper>
                <Input {...props}
                    value={value}
                    onFocus={(e) => { setFocused(true); onFocus(e); }}
                    onBlur={(e) => { setFocused(false); onBlur(e); }}
                    focused={isFocused ? 'focused' : null}
                    error={errors.length}
                />
                {icon && <InputIcon icon={icon} onClick={onIconClick} />}
            </InputWrapper>
            <InputErrors errors={errors} />
        </div>
    );
}


export const propTypes = {
    ...baseProps,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errors: errorProps.errors,
    label: labelProps.label,
    icon: PropTypes.shape({ source: PropTypes.string, alt: PropTypes.string }),
    onIconClick: iconProps.onClick,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
}

export const defaultProps = {
    onFocus: () => { },
    onBlur: () => { },
    errors: [],
    value: ''
}

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default styled(TextInput)`
    flex: 1;
    max-width: 250px;
    min-width: 200px;
    margin: 0.5em;
`