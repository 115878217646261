export const antonioBano = {
    source: require('./Antonio-Bano.jpg'),
    alt: 'A photo of Antonio Bano'
};

export const fernandoChavez = {
    source: require('./Fernando-Chavez.jpg'),
    alt: 'A photo of Antonio Bano',
};

export const fieldMitchell = {
    source: require('./Field-Mitchell.jpg'),
    alt: 'A photo of Field Mitchell',
};

export const jonMckee = {
    source: require('./Jon-Mckee.jpg'),
    alt: 'A photo of a chicken',
};

export const kathyMathieu = {
    source: require('./Kathy-Mathieu.jpg'),
    alt: 'A photo of Kathy Mathieu',
};

export const loganGrosz = {
    source: require('./Logan-Grosz.jpg'),
    alt: 'A photo of Logan Grosz',
};

export const mikeAhlers = {
    source: require('./Mike-Ahlers.jpg'),
    alt: 'A photo of Mike Ahlers',
};

export const mitchellPeterson = {
    source: require('./Mitchell-Peterson.jpg'),
    alt: 'A photo of Mitchell Peterson',
};

export const philipTelkamp = {
    source: require('./Philip-Telkamp.jpg'),
    alt: 'A photo of Philip Telkamp',
};

export const rileyKopp = {
    source: require('./Riley-Kopp.jpg'),
    alt: 'A photo of Riley Kopp',
};

export const timFord = {
    source: require('./Tim-Ford.jpg'),
    alt: 'A photo of Timothy Ford',
};

export const nickClancy = {
    source: require('./Nick-Clancy.jpg'),
    alt: 'A photo of Nick Clancy',
};

export const vytasSolderholm = {
    source: require('./Vytas-Solderholm.jpg'),
    alt: 'A photo of Vytas Solderholm',
};

export const willTrevillyan = {
    source: require('./William-Trevillyan.jpg'),
    alt: 'A photo of William Trevillyan',
};

export const nateFagrey = {
    source: require('./Nate-Fagrey.jpg'),
    alt: 'A photo of Nate Fagrey',
};