import React from 'react';
import styled from 'styled-components';
import { P } from '../common/text';

const CopyrightText = styled(P)`
    color: #fff;
    margin: 0.5em;
    padding: 0;
    font-size: 0.5em;
    text-align: center;
`

export const Copyright = () => (
    <CopyrightText>Copyright © 2020 HomeMetrics - All Rights Reserved.</CopyrightText>
)

export default Copyright;