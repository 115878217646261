import React from 'react';
import PropTypes from 'prop-types';
import { P, H2 } from '../../common/text';
import styled from 'styled-components';
import { Image } from '../../common/Image';

const CardWrap = styled.div`
    width: 300px;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 2px #bbb;
    margin: 8px;
`

const Title = styled(H2)`
    text-align: center;
`

const Body = styled(P)`

`

const StyledImage = styled(Image)`
    width: 80%;
    height: auto;
    margin: 1em 10%;
`

export const ImageCard = ({ className, title, image, body }) => {
    return (
        <CardWrap className={className}>
            <Title>{title}</Title>
            <StyledImage {...image} />
            <Body>{body}</Body>
        </CardWrap>
    )
}

const propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({ source: PropTypes.string.isRequired, alt: PropTypes.string.isRequired }).isRequired,
    body: PropTypes.string,
}

ImageCard.propTypes = propTypes;

export default ImageCard;