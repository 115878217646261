import React from 'react';
import TextInput from './TextInput';

export const formatPhoneNumber = (phoneNumber) => {
    const regex = /^(\d{3}).*(\d{3}).*(\d{4}).*$/
    return phoneNumber.replace(regex, '($1) $2-$3');
}

export const stripPhoneNumber = (phoneNumber) => {
    const regex = /^.*(\d{3}).*(\d{3}).*(\d{4}).*$/
    return phoneNumber.replace(regex, '$1$2$3');
}


export const PhoneInput = ({ value, onValueChange, ...props }) => {
    return (
        <TextInput
            {...props}
            value={value ? formatPhoneNumber(value) : value}
            onValueChange={(value, ...args) => onValueChange(stripPhoneNumber(value), ...args)}
        />
    )

}

export default PhoneInput
