import HouseViewModel from './models/HouseViewModel'
import HouseDataModel from './models/HouseDataModel'

export const baseAPI = process.env.REACT_APP_API_BASE_URL

export const addHouse = async (jwt, houseViewModel) => {
    const body = dataToModel(HouseDataModel, houseViewModel)

    const houseResponse = await fetch(`${baseAPI}/house/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify(body)
    })

    const houseResult = await houseResponse.json()

    if (houseResponse.status !== 200) {
        return { isValid: false, message: houseResult.detail }
    }

    const prefResponse = await fetch(`${baseAPI}/preferences/house/${houseResult.house_id}`, {
        method: "PATCH",
        headers: {
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(body.house_preferences)
    })

    const prefResult = await prefResponse.json()

    if (prefResponse.status !== 200) {
        return { isValid: false, message: prefResult.detail }
    }

    houseResult.house_preferences = prefResult

    return { isValid: true, data: dataToModel(HouseViewModel, houseResult) }
}

export const removeHouse = async (jwt, houseId) => {
    const response = await fetch(`${baseAPI}/house/${houseId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${jwt}`
        }
    })

    const result = await response.json()

    if (response.status !== 200) {
        return { isValid: false, message: result.detail }
    }

    return { isValid: true, data: houseId }
}

export const updateHouse = async (jwt, houseId, houseViewModel) => {
    const { house_preferences: housePreferences, ...houseDataModel } = dataToModel(HouseDataModel, houseViewModel)

    const houseRequest = fetch(`${baseAPI}/house/${houseId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(houseDataModel)
    })

    const prefRequest = fetch(`${baseAPI}/preferences/house/${houseId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(housePreferences)
    })

    const [houseResponse, prefResponse] = await Promise.all([houseRequest, prefRequest])

    const houseResult = await houseResponse.json()
    const prefResult = await prefResponse.json()

    if (houseResponse.status !== 200) {
        return { isValid: false, message: houseResult.detail }
    }

    if (prefResponse.status !== 200) {
        return { isValid: false, message: prefResult.detail }
    }

    houseResult.house_preferences = prefResult

    return { isValid: true, data: dataToModel(HouseViewModel, houseResult) }
}

export const fetchHouses = async (jwt) => {
    const response = await fetch(`${baseAPI}/house/houseModels`, { headers: { 'Authorization': `Bearer ${jwt}` } })

    const result = await response.json()

    if (response.status !== 200) {
        return { isValid: false, message: result.detail }
    }

    return { isValid: true, data: dataToModel(HouseViewModel, result) }
}

export const fetchHouse = async (jwt, id) => {
    // Currently preferences and houses are available at 2 different endpoints for any non-multiple requests
    const houseReq = fetch(`${baseAPI}/house/${id}`, { headers: { 'Authorization': `Bearer ${jwt}` } })
    const prefReq = fetch(`${baseAPI}/preferences/house/${id}`, { headers: { 'Authorization': `Bearer ${jwt}` } })

    const [houseResponse, prefResponse] = await Promise.all([houseReq, prefReq])

    const houseResult = await houseResponse.json()
    const prefResult = await prefResponse.json()

    if (houseResponse.status !== 200) {
        return { isValid: false, message: houseResult.detail }
    }

    if (prefResponse.status !== 200) {
        return { isValid: false, message: prefResult.detail }
    }

    houseResult.house_preferences = prefResult;

    return { isValid: true, data: dataToModel(HouseViewModel, houseResult) }
}

const dataToModel = (Model, data) => {
    return Array.isArray(data) ? data.map(d => Model(d)) : Model(data)
}