import React from 'react';
import YouTube from "react-youtube";

import './device.css';

class Device extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedPlatform: 0
        };
    }

    platforms = [
        {id:0, platform:'', desc:'', app_link: '', features: '', video: '', version: ''},
        {id:1, platform:'Android', desc: 'App Description', app_link: 'future link', features:
                "Feature List;" +
                "Message Batman", video: '_QyYaPWasos', version: '1.0'},
        {id:2, platform: 'iOS', desc: '', app_link: '', features: '', video: 'dQw4w9WgXcQ', version: ''}
        ];
    handleClick = (platform)=>{
        this.setState({
            selectedPlatform: parseInt(platform.id)
        });
    }

    onReady = (event) => {
        // eslint-disable-next-line
        setPlayer(event.target);
    };

    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
                autoplay: 1,
            },
        };

        let deviceList = this.platforms.map(platform=>{
            if (platform.platform !== ''){
                return(
                    <div className="platform">
                        <button onClick={()=>{this.handleClick(platform)}}>
                        {platform.platform}
                        </button>
                    </div>
                )
            }else{
                return('')
            }
        })

        let currPlatform = this.platforms.map(platform=>{
            if (platform.id === this.state.selectedPlatform
                && platform.platform !== ''){
                let description = (platform.desc !== '') ? (<div className={"description"}>
                    {platform.desc}
                </div>) : ('');
                let features = (platform.features !== '') ? (    <div className="platform-features">
                    <p><ul>{platform.features.split(";").map(feature=>{
                        return(
                            <li>{feature}</li>
                        )
                    })}</ul></p>
                </div>) : ('');
                let video = (platform.video !== '') ? (<div className="platform-video">
                    <YouTube videoId={platform.video} opts={opts} onReady={this._onReady} /></div>) : ('');
                let version = (platform.version !== '') ? (<div className="platform-version">Version: {platform.version}</div>) : ('');
                let applink = (platform.app_link !== '') ? (<div className="platform-applink">Store Link: {platform.app_link}</div>) : ('');
                return(
                    <div className={"platform-detail"}>
                        {description}
                        {applink}
                        {features}
                        {video}
                        {version}
                    </div>
                )
            }else{
                return ('')
            }
        })

        return(
            <div className='main'>
                Monitor your home from our easy to use apps.

                <div className={"platform-list"}>
                    {deviceList}
                </div>

                <div>
                    {currPlatform}
                </div>
            </div>
        )

    }
}

export default Device;