import React from 'react';
import PropTypes from 'prop-types';
import { H1, H2 } from '../../common/text';
import { PrimaryButton } from '../../common/Buttons';
import { Image } from '../../common/Image';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ProductWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 16px;
    box-shadow: 1px 1px 2px 2px gray;
    padding: 16px;
    width: 800px;
    borderRadius: 4px;
`

const StyledImage = styled(Image)`
    width: 100%;
    height: auto;
    border-radius: 50%;
`
const TitleText = styled(H1)`
    text-align: left;
    font-size: 2em;
    margin-bottom: none;
`

const DescriptionText = styled(H2)`
    font-size: 1.2em;
    font-style: italic;
    margin-bottom: 16px;
`

const ContentWrap = styled.div`
    flex: 2;
    padding: 32px;
    border-left: solid 1px gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ImageWrap = styled.div`
    width: 250px;
    padding: 32px;
    flex: 1;
    margin: 32;
    display: flex;
    justify-content: center;
    align-items: center;

`

const ListItem = styled.li`
    margin: 16px 0;
`

const StyledLink = styled(Link)`
    text-align: center;
`

const SubmitButton = styled(PrimaryButton)`
    left: 0;
    right: 0;
    margin: auto;
`

const List = styled.ul`

`

export const Product = ({ title, description, image, features, onAdd }) => {
    return (
        <ProductWrap>
            <ImageWrap>
                <StyledImage source={image.source} alt={image.alt} />
            </ImageWrap>
            <ContentWrap>
                <TitleText>{title}</TitleText>
                <DescriptionText>{description}</DescriptionText>
                <List>
                    {features.map((feature, i) => {
                        return (
                            <ListItem key={i}>{feature}</ListItem>);
                    }
                    )}
                </List>
                <StyledLink to={'/user/signup'}>
                    <SubmitButton onClick={onAdd}>Sign Up!</SubmitButton>
                </StyledLink>
            </ContentWrap>
        </ProductWrap >
    );
}

export const propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({ source: PropTypes.string.isRequired, alt: PropTypes.string.isRequired }).isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    onAdd: PropTypes.func,
}

Product.propTypes = propTypes;

export default Product;
