import React from 'react';
import { PrimaryButton } from '../Buttons'
import PropTypes from 'prop-types';
import { Loader } from '../Loader';
import { hasLoading } from '../../hoc/hasLoading';
import styled from 'styled-components';
import Errors from '../Inputs/Errors';

const StyledErrors = styled(Errors)`
    text-align: center;
`

const StyledLoader = styled(Loader)`
    padding: 4px 24px;
    margin: 8px;
`

const SubmitButton = hasLoading(StyledLoader)(PrimaryButton);

export const Form = ({ children, className, onSubmit, errors, isLoading, submitText }) => {
    return (
        <form className={className}>
            {children}
            <StyledErrors errors={errors} />
            <SubmitButton isLoading={isLoading} onClick={(e) => { onSubmit(e); e.preventDefault(); }}>{submitText}</SubmitButton>
        </form>
    );
}


export const propTypes = {
    onSubmit: PropTypes.func,
    errorMessages: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    submitText: PropTypes.string
}

export const defaultProps = {
    onSubmit: () => { },
    errorMessages: [],
    isLoading: false,
    submitText: 'Submit'
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;