import React from 'react';
import { useSelector } from 'react-redux'
import { selectAuth } from '../../store/user/userReducer'

export const hasAuth = (needsAuth) => (WrappedComponent) => {
    const HasAuth = (...props) => {
        const isLoggedIn = useSelector(selectAuth)

        const shouldDisplay = (needsAuth && isLoggedIn) || (!needsAuth && !isLoggedIn);

        return shouldDisplay ? <WrappedComponent {...props} /> : null;
    }

    HasAuth.displayName = `hasAuth(${WrappedComponent.name})`

    return HasAuth
}

export default hasAuth;