import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as userApi from './userApi'

export const LoadStates = {
    IDLE: 'idle',
    FETCHING: 'fetching',
    FETCHED: 'fetched',
    ERROR: 'error'
}

export const createUser = createAsyncThunk('user/createUser', async (user, { rejectWithValue }) => {
    const response = await userApi.createUser(user)
    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const signIn = createAsyncThunk('user/signIn', async ({ email, password }, { rejectWithValue }) => {
    const response = await userApi.signIn(email, password)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)

    const response = await userApi.fetchUser(jwt)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

export const updateUser = createAsyncThunk('user/updateUser', async (userViewModel, { getState, rejectWithValue }) => {
    const state = getState()
    const jwt = selectJwt(state)

    const response = await userApi.updateUser(jwt, userViewModel)

    if (response.isValid) {
        return response.data
    } else {
        return rejectWithValue(response.message)
    }
})

const initialState = {
    user: null,
    admin: false,
    jwt: '',
    status: LoadStates.IDLE,
    error: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducer: {
        signOut(state) {
            state.jwt = ''
        }
    },
    extraReducers: {
        [signIn.pending]: (state, _) => {
            state.status = LoadStates.FETCHING
        },
        [signIn.fulfilled]: (state, action) => {
            state.status = LoadStates.FETCHED
            state.jwt = action.payload
        },
        [signIn.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload
        },
        [fetchUser.pending]: (state, _) => {
            state.status = LoadStates.FETCHING
        },
        [fetchUser.fulfilled]: (state, action) => {
            state.status = LoadStates.FETCHED
            state = { ...state, ...action.payload }
        },
        [fetchUser.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload
        },
        [createUser.pending]: (state, _) => {
            state.status = LoadStates.FETCHING
        },
        [createUser.fulfilled]: (state, action) => {
            state.status = LoadStates.FETCHED
            state.user = action.payload
        },
        [createUser.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload
        },
        [updateUser.pending]: (state, _) => {
            state.status = LoadStates.FETCHING
        },
        [updateUser.fulfilled]: (state, action) => {
            state.status = LoadStates.FETCHED
            state.user = action.payload
        },
        [updateUser.rejected]: (state, action) => {
            state.status = LoadStates.ERROR
            state.error = action.payload
        }
    }
})

export const selectJwt = state => state.user.jwt
export const selectAuth = state => state.user.jwt
export const selectUser = state => state.user.user
export const selectStatus = state => state.user.status
export const selectError = state => state.user.error

export const { signOut } = userSlice.actions

export default userSlice.reducer