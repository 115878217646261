import {
    fernandoChavez,
    jonMckee,
    kathyMathieu,
    loganGrosz,
    mitchellPeterson,
    nateFagrey,
    nickClancy,
    philipTelkamp,
    rileyKopp,
    timFord,
    willTrevillyan,
} from '../img/team/compressed'

export default [
    {
        id: 'wTrevillyan',
        name: 'William Trevillyan',
        image: willTrevillyan,
        title: 'CEO',
        linkedIn: 'https://www.linkedin.com/in/williamtrevillyan/',
    },
    {
        id: 'tFord',
        name: 'Timothy Ford',
        image: timFord,
        title: 'CTO',
        linkedIn: 'https://www.linkedin.com/in/timothy-ford-aaab29182/',
    },
    {
        id: 'rKopp',
        name: 'Riley Kopp',
        image: rileyKopp,
        title: 'COO',
        linkedIn: 'https://www.linkedin.com/in/riley-kopp-1232071a9/',
    },
    {
        id: 'jMcKee',
        name: 'Jon McKee',
        image: jonMckee,
        title: 'Dev Ops Engineer',
        linkedIn: 'https://www.linkedin.com/in/jon-mckee/',
    },
    {
        id: 'mPeterson',
        name: 'Mitchell Peterson',
        image: mitchellPeterson,
        title: 'App Developer',
        linkedIn: 'https://www.linkedin.com/in/mitchell-peterson-611b29128/',
    },
    {
        id: 'nClancy',
        name: 'Nick Clancy',
        image: nickClancy,
        title: 'Marketing Specialist',
        linkedIn: 'https://www.linkedin.com/in/nick-c-a9655b8a/',
    },
    {
        id: 'fChavez',
        name: 'Fernando Chavez',
        image: fernandoChavez,
        title: 'Manufacturing Specialist',
        linkedIn: 'https://www.linkedin.com/in/fernando-chavez-10260317a/',
    },
    {
        id: 'kMathieu',
        name: 'Kathy Mathieu',
        image: kathyMathieu,
        title: 'Manufacturing Specialist',
        linkedIn: 'https://www.linkedin.com/in/katherine-mathieu-5545071b7/',
    },
    {
        id: 'pTelkamp',
        name: 'Philip Telkamp',
        image: philipTelkamp,
        title: 'Web Developer',
        linkedIn: 'https://www.linkedin.com/in/philip-telkamp-173a6914a/',
    },
    {
        id: 'lGrosz',
        name: 'Logan Grosz',
        image: loganGrosz,
        title: 'App Developer',
        linkedIn: 'https://www.linkedin.com/in/logan-grosz-ab956614a/',
    },
    {
        id: 'nFagrey',
        name: 'Nate Fagrey',
        image: nateFagrey,
        title: 'Full Stack Developer',
        linkedIn: 'https://www.linkedin.com/in/nathanielfagrey/',
    }
]
