import React, { memo } from 'react'

const styles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
}

export const MultiInput = (props) => {
    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}

export default memo(MultiInput)