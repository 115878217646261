import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const Image = ({ source, alt, className, style, ...props }) => {
    return (
        <img {...props} src={source} alt={alt} className={className} style={style} />
    );
}

export const propTypes = {
    source: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

Image.propTypes = propTypes;

export default memo(Image);