import React from 'react';
import { PrimaryDrawer } from '../common/Drawer';
import { CloseIcon } from '../../img/icons';
import { IconButton } from '../common/Buttons';
import styled, { css } from 'styled-components';
import AccountDrawerButton from './AccountDrawerButton';

export const CloseButton = styled(IconButton)`
    margin-left: auto;
`

export const AccountDrawer = ({ isOpen, className, onClose }) => {
    return (
        <PrimaryDrawer side={'right'} isOpen={isOpen} className={className}>
            <CloseButton Icon={CloseIcon} onClick={onClose} />
            <AccountDrawerButton onClick={onClose} to="/houses">Houses</AccountDrawerButton>
        </PrimaryDrawer>
    )
}


export default styled(AccountDrawer)`
    background-color: #fff;
    align-items: center;
    
    ${props => props.isOpen && css`
        border-left: solid 2px ${props => props.theme.darkAccent};
    `}

`;