import React from 'react';
import { PanelContent } from './';
import styled from 'styled-components';

const StyledPanelContent = styled(PanelContent)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

export const CardPanel = ({ children, className }) => (
    <StyledPanelContent className={className}>
        {children}
    </StyledPanelContent>
)

export default CardPanel;