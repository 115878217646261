import styled from 'styled-components';
import P from './P';

export const LabelText = styled(P)`
    font-size: 8pt;
    font-color: 'gray';
    margin-bottom: 0px;
    padding: 0px;
    text-align: left;
`

export default LabelText;