import React from 'react';
import Errors from './Errors';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from './Label';
import RadioButton from './RadioButton';

export const RadioOption = styled(RadioButton)`
    margin: 0.5em;
`

export const RadioInput = ({ className, label, errors, options, value, onValueChange, name }) => {

    return (
        <div className={className}>
            <Label label={label} />
            {options.map((option) => (
                <RadioOption key={option.value} onCheck={(value) => onValueChange(value, name)} isChecked={value === option.value} {...option} />
            ))}
            <Errors errors={errors} />
        </div>
    )
}

export const propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onValueChange: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }))
}

RadioInput.propTypes = propTypes;

export default styled(RadioInput)``;
