import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const Page = ({ children, className }) => (
    <PageWrapper className={className}>{children}</PageWrapper>
);

export default Page;