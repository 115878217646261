import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../Base/Panel';
import { withStyle } from '../../hoc';

const styles = {
    image: {
        width: '100%',
        height: 'auto',
    }
}

export const ImagePanel = ({ style, image, filter }) => {
    const StyledPanel = withStyle(style)(Panel);
    const imageStyle = { ...styles.image, filter: filter };

    return (
        <StyledPanel>
            <img style={imageStyle} src={image.source} alt={image.alt} />
        </StyledPanel>
    );
}

export const propTypes = {
    image: PropTypes.shape({ source: PropTypes.string.isRequired, alt: PropTypes.string.isRequired }).isRequired,
    filter: PropTypes.string,
    style: PropTypes.object,
}

ImagePanel.propTypes = propTypes;

export default memo(ImagePanel);