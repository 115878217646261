import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllHouses, selectHouseStatus, LoadStates, fetchHouses, removeHouse, updateHouse } from '../../../store/houses/housesReducer'
import { hasLoading, requiresAuth } from '../../hoc';
import HouseCardList from './HouseCardList';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Unauthenticated } from '../../'
import { Loader } from '../../common/Loader';
import { H2 } from '../../common/text';

const CenterWrap = styled.div`
    text-align: center;
`

const Loading = () => (<CenterWrap><H2>Loading Houses...</H2><Loader /></CenterWrap>)

const Unauthorized = () => (<Unauthenticated />)

const NoHouses = () => (<CenterWrap><H2>No houses found...</H2></CenterWrap>)

const HouseList = hasLoading(Loading)(HouseCardList)

export const HouseContainer = () => {
    const history = useHistory()

    const dispatch = useDispatch()

    const houses = useSelector(selectAllHouses)
    const houseStatus = useSelector(selectHouseStatus)

    useEffect(() => {
        if (houseStatus === LoadStates.UNFETCHED) {
            dispatch(fetchHouses())
        }
    }, [houseStatus, dispatch])

    const handleDelete = (id) => {
        if (window.confirm(`Are you sure you want to delete house: ${id}`))
            dispatch(removeHouse(id))
    }

    const handleSelect = (id) => {
        if (history.location !== `/houses/${id}`)
            history.push(`/houses/${id}`)
    }

    const handleFavorite = (id) => {
        const currHouse = houses.find(h => h.id === id)
        const newHouse = { ...currHouse, preferences: { ...currHouse.preferences } }
        newHouse.preferences.isFavorite = !newHouse.preferences.isFavorite

        dispatch(updateHouse({ id, house: newHouse }))
    }

    if (houses.length)
        return <HouseList data={houses} onDelete={handleDelete} onSelect={handleSelect} onFavorite={handleFavorite} isLoading={houseStatus === LoadStates.FETCHING} />
    else
        return <NoHouses />
}


export default styled(requiresAuth(Unauthorized)(HouseContainer))``
