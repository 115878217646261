import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from './common/Buttons';
import { LinkedInIcon, FacebookIcon, InstagramIcon } from '../img/icons'

const socialLinks = [
    {
        Icon: FacebookIcon,
        alt: 'Instagram',
        href: 'https://www.instagram.com/home.metrics/'
    },
    {
        Icon: LinkedInIcon,
        alt: 'Facebook',
        href: 'https://www.facebook.com/HomeMetrics.io'
    },
    {
        Icon: InstagramIcon,
        alt: 'LinkedIn',
        href: 'https://www.instagram.com/homemetrics.io/'
    }
]


export const Socials = ({ isDark, className }) => {
    return (
        <>
            {socialLinks.map((social, i) => {
                return (<IconButton key={i} className={className} Icon={social.Icon} href={social.href} isDark={isDark} />);
            })}
        </>
    )
}

export const propTypes = {
    isDark: PropTypes.bool
}

export const defaultProps = {
    isDark: false
}

Socials.propTypes = propTypes;
Socials.defaultProps = defaultProps;

export default Socials;