import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DropDownOption from './DropDownOption';
import Label from './Label';
import Errors from './Errors';
import styled, { css } from 'styled-components';

export const DropDownSelect = styled.select`
    border: solid 1px gray;
    border-radius: 4px;
    padding: 0.65em;
    outline: none;
    width: 100%;
    cursor: pointer;

    &:hover {
        border: solid 1px black;
    }

    ${props => props.focused && css`
        border: solid 1px ${props.theme.primary};

        &:hover {
            border: solid 1px ${props.theme.primary};
        }
    `}

    ${props => props.error && css`
        border: solid 1px ${props.theme.errorColor};

        &:hover {
            border: solid 1px ${props.theme.errorColor};
        }
    `}
`

export const InputLabel = styled(Label)`
    color: gray;
    font-size: 0.75em;
    font-family: ${props => props.theme.baseFont};
    margin: 0;
    text-align: left;
    padding: 0;
`

export const DropDownInput = ({ className, label, errors, disabled, onFocus, onBlur, options, value, onValueChange, multiple, placeholder }) => {
    const [isFocused, setFocused] = useState(false)

    return (
        <div className={className}>
            <InputLabel label={label} />
            <DropDownSelect
                value={value}
                onBlur={(...args) => { setFocused(false); onBlur(args) }}
                onChange={e => onValueChange(e.target.value, e.target.name)}
                onFocus={(...args) => { setFocused(true); onFocus(...args) }}
                disabled={disabled}
                multiple={multiple}
                focused={isFocused}
                hasValue={value !== ''}
            >
                {placeholder && <DropDownOption disabled hidden defaultValue value={''} display={placeholder} />}
                {options.map(option => <DropDownOption key={option.value} {...option} />)}
            </DropDownSelect>
            <Errors errors={errors} />
        </div>
    )
}

export const propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    onValueChange: PropTypes.func,
    placeholder: PropTypes.string,
}

export const defaultProps = {
    onFocus: () => { },
    onBlur: () => { },
    disabled: false,
    multiple: false,
}

DropDownInput.propTypes = propTypes;
DropDownInput.defaultProps = defaultProps;

export default styled(DropDownInput)`
    flex: 1;
    max-width: 250px;
    min-width: 200px;
    margin: 0.5em;
`