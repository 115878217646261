import React from 'react';
import PropTypes from 'prop-types';

export const CheckableInput = ({ value, onCheck, className, children, ...props }) => (
    <div
        {...props}
        className={className}
        onClick={() => onCheck(value)}
    >
        {children}
    </div>
)

export const propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    toggleCheck: PropTypes.func,
}

CheckableInput.propTypes = propTypes

export default CheckableInput