import React from 'react';
import { P, H1 } from '../../common/text';
import styled from 'styled-components';
import { DeleteIcon, EditIcon, FavoriteIcon, FavoriteBorderIcon } from '../../../img/icons'
import { IconButton } from '../../common/Buttons';

const CardWrap = styled.div`
    border-radius: 4px;
    box-shadow: 2px 2px 2px 2px #bbb;
    margin: 8px;
    position: relative;
    padding: 1em;
`

const Header = styled.div`
    display: flex;
    align-items: flex-start;
`;

const HouseName = styled(H1)`
    margin-bottom: 0;
`

const FavoriteButton = styled(IconButton)`
    margin-left: auto;
`

const DeleteButton = styled(IconButton)`
    margin-right: auto;
`

const ButtonWrap = styled.div`
    display: flex;
`

const NameWrap = styled.div`
    margin-right: 2em;
`

export const HouseCard = ({
    id,
    className,
    onDelete,
    onSelect,
    preferences,
    primaryAddress,
    city,
    state,
    zipCode,
    onFavorite
}) => {

    return (
        <CardWrap className={className}>
            <Header>
                <NameWrap>
                    <HouseName>{preferences.houseName}</HouseName>
                    <P>{primaryAddress}, {city} {state}, {zipCode}</P>
                </NameWrap>
                <FavoriteButton Icon={preferences.isFavorite ? FavoriteIcon : FavoriteBorderIcon} isDark={true} onClick={() => onFavorite(id)} />
            </Header>
            <ButtonWrap>
                <DeleteButton isDark={true} Icon={DeleteIcon} onClick={() => onDelete(id)} />
                <IconButton Icon={EditIcon} isDark={true} onClick={() => onSelect(id)} />
            </ButtonWrap>
        </CardWrap >
    )
}

export default HouseCard;