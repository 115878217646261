import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ErrorMessage = ({ className, message }) => (
    <p className={className}>{message}</p>
)

export const propTypes = {
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
};

ErrorMessage.propTypes = propTypes;

export default styled(ErrorMessage)`
    color: red;
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.baseFont};
`;