import React, { useState } from 'react';
import { whiteSecondary, whiteIcon } from '../img/logos';
import { GroupsIcon, ListIcon, SupportIcon } from '../img/icons';
import { NavMenuContainer } from './NavMenu';
import { TransparentButton, PrimaryButton } from './common/Buttons';
import { withResponsive } from './hoc';
import { Link, useLocation } from 'react-router-dom';
import { ScreenSizes } from './context/ScreenSizeContext';
import styled from 'styled-components';
import routes, { home, team, products, signUp, support } from '../routes';
import { Image } from './common/Image';
import { SignInButton } from './SignIn';
import { AccountButton } from './Account';

const navMenuLinks = [
    {
        id: 'theTeam',
        label: 'The Team',
        href: team.path,
        Icon: GroupsIcon,
    },
    {
        id: 'products',
        label: 'Products',
        href: products.path,
        Icon: ListIcon
    },
    {
        id: 'support',
        label: 'Support',
        href: support.path,
        Icon: SupportIcon
    }
]

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: background 0.25s;
    position: ${props => props.hasHeaderImage ? 'fixed' : 'sticky'};
    background: ${props => props.hasHeaderImage ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.9)'};
    top: 0;
    width: 100%;
    z-index: 4;
    padding: 0px 6px;

    &.scrolled {
        background: rgba(0, 0, 0, 0.9);
    }
`

const LogoButton = styled(Link)`
    margin-right: auto;
    align-self: center;

    &.mobile {
        margin-right: 0;
    }
`

const HeaderImage = styled(Image)`
    & {
        height: auto;
        width: 250px;
        filter: drop-shadow(1px 1px 2px #000);
    }

    &.mobile {
        height: 48px;
        width: 48px;
        margin: 1em;
    }
`

const StyledButton = styled(PrimaryButton)`
    margin: 6px;
    margin-left: 8px;
    box-shadow: 1px 1px 4px #111;
`

const SignUpButton = styled(Link)`
    &.mobile {
        margin-left: auto;
    }
`

export const Header = ({ useMobile }) => {
    const location = useLocation();
    const [isScrolled, setScrolled] = useState(false);

    window.addEventListener("scroll", () => {
        if (window.pageYOffset !== 0 && !isScrolled) {
            setScrolled(true);
        }

        if (window.pageYOffset === 0 && isScrolled) {
            setScrolled(false);
        }
    })

    return (
        <HeaderWrapper hasHeaderImage={
            routes.filter((route) => (
                route.path === location.pathname && route.headingImage != null
            )).length > 0
        }
            className={isScrolled ? "scrolled" : ""}
        >
            <LogoButton to={home.path} className={`${useMobile ? 'mobile' : null}`}>
                <TransparentButton>
                    <HeaderImage className={`${useMobile ? 'mobile' : null}`} {...(useMobile ? whiteIcon : whiteSecondary)} />
                </TransparentButton>
            </LogoButton>

            <NavMenuContainer data={navMenuLinks} />

            <SignUpButton to={signUp.path} className={`${useMobile ? 'mobile' : null}`}>
                <StyledButton>Sign Up</StyledButton>
            </SignUpButton>
            <SignInButton />
            <AccountButton />
        </HeaderWrapper >
    );
}

export default withResponsive(ScreenSizes.TABLET)(Header)