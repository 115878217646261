import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage, { propTypes as errorProps } from './ErrorMessage';
import styled from 'styled-components';

export const ErrorText = styled(ErrorMessage)``

export const Errors = ({ className, errors }) => (
    <div className={className}>
        {errors.map(({ type, ...error }) => (<ErrorText key={type} {...error} />))}
    </div>
)

export const propTypes = {
    className: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, ...errorProps })).isRequired
}

Errors.propTypes = propTypes

export default styled(Errors)``