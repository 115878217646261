import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Base/Panel'
import styled from 'styled-components';

export const Video = styled.video`
    width: 100%;
    height: auto;
`

export const VideoPanel = ({ className, source, type }) => (
    <Panel className={className}>
        <Video loop autoPlay muted>
            <source src={source} type={type} />
        </Video>
    </Panel>
)

const propTypes = {
    source: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string
}

VideoPanel.propTypes = propTypes

export default VideoPanel