import React from 'react';
import { Socials } from '..';
import styled from 'styled-components';
import FooterIcon from './FooterIcon';
import Copyright from './Copyright';
import ContactLink from './ContactLink';

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 32px;
    color: #fff;
    text-decoration: none;

    &.mobile {
        flex-direction: column;
    }

`

const SectionWrapper = styled.div`
    margin: 8px;
    text-align: center;
`



// A lot of this display logic for resizing needs to be pulled into its own component

export const DesktopFooter = () => {

    return (
        <FooterWrapper>
            <SectionWrapper>
                <Socials isDark={true} />
            </SectionWrapper>
            <SectionWrapper>
                <FooterIcon />
                <Copyright />
            </SectionWrapper>
            <SectionWrapper>
                <ContactLink />
            </SectionWrapper>
        </FooterWrapper>
    );
}

export default DesktopFooter;