import React from 'react';
import PropTypes from 'prop-types';
import Base, { propTypes as basePropTypes, defaultProps as baseDefaultProps } from './Base/Base'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Navigation = styled(NavLink)`
    text-decoration: none;
    text-shadow: 1px 1px 1px #000;
    background: none;
    color: ${props => props.theme.lightBase};
    padding: 16px;
    width: 130px;
    justify-content: center;
    flex: 1;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: none;
        color: ${props => props.theme.darkAccent};
        text-shadow: none;
    }

    &.active {
        background: ${props => props.theme.lightBase};
        color: ${props => props.theme.darkBase};
        text-shadow: none;
        box-shadow: 1px 1px 4px #111;
    }
`

const Button = styled(Base)`
    background: none;
    border: none;
    outline: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.125s;

    &:hover {
        background: ${props => props.theme.lightAccent};
    }
`

export const DesktopNavButton = ({ href, label, ...props }) => {

    return (
        <Button {...props} >
            <Navigation to={href} activeClassName='active'>
                {label}
            </Navigation>
        </Button>
    );
}

export const propTypes = {
    ...basePropTypes,
    href: PropTypes.string,
    isFocused: PropTypes.bool,
    label: PropTypes.string.isRequired,
}

export const defaultProps = {
    ...baseDefaultProps,
    href: '#',
}

DesktopNavButton.propTypes = propTypes;
DesktopNavButton.defaultProps = defaultProps;

export default DesktopNavButton;