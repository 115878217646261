import React from 'react';
import HomeImg from '../img/works-home.jpg'
import { waterLeakIcon, floodingIcon, waterUsageIcon, electricityUsageIcon } from '../img/icons';
import { ImagePanel, Panel, PanelContent } from '../components/Panels';
import { ImageCardList } from '../components/cards';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../components/common/Buttons';
import { works } from '../routes';
import { Page } from '../components';


const images = {
    homeImage: {
        source: HomeImg,
        alt: 'An image of a home'
    }
}

const cards = [
    {
        id: 0,
        title: 'Water Leaks',
        image: waterLeakIcon,
        body: 'Water leaks are detected within minutes via leak detection sensors ensuring water damage does not build up over time.'
    },
    {
        id: 1,
        title: 'Electrical Usage',
        image: electricityUsageIcon,
        body: 'Electricity usage is tracked via electrical meters which get installed in a properties electrical box, optimizing your energy usage.'
    },
    {
        id: 2,
        title: 'Floods',
        image: floodingIcon,
        body: 'Floods and broken pipes are identified preventing any water damage from propagating, followed by an automated water-shut off valve kicking in.'
    },
    {
        id: 3,
        title: 'Water Usage',
        image: waterUsageIcon,
        body: 'Water usage is monitored helping you save on your utilitiy bills, in addition to providing you with info on your daily water habits'
    }
]

class Works extends React.Component {
    render() {
        return (
            <Page>
                <ImagePanel image={works.headingImage} />
                <Panel>
                    <ImageCardList data={cards} />
                </Panel>
                <ImagePanel image={images.homeImage} />
                <Panel style={{ textAlign: 'center' }} >
                    <PanelContent>
                        <Link to={'/products'}>
                            <PrimaryButton buttonTitle={'Learn more about the Metrics Network'} />
                        </Link>
                    </PanelContent>
                </Panel>
            </Page>
        )

    }
}

export default Works;