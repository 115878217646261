import PhoneTypes from './PhoneTypes'

export default class UserViewModel {
    firstName;
    lastName;
    email;
    phone;
    phoneType;

    constructor(userDataModel) {
        this.firstName = userDataModel.first_name
        this.lastName = userDataModel.last_name
        this.email = userDataModel.email
        this.phone = userDataModel.phone
        this.phoneType = PhoneTypes.display[userDataModel.phone_type]
    }
}