import PrimaryButton from './PrimaryButton';
import styled from 'styled-components';

export default styled(PrimaryButton)`
    background-color: ${props => props.theme.secondary};
    border-color: ${props => props.theme.secondary};

    &:hover { 
        background-color: ${props => props.theme.secondaryAccent};
        color: ${props => props.theme.secondary};
    }
`