export const Home = {
    source: require('./Home.jpg'),
    alt: 'A HomeMetrics Water sensor',
};

export const Support = {
    source: require('./Support.jpg'),
    alt: 'A HomeMetrics sensor',
};

export const Team = {
    source: require('./Team.jpg'),
    alt: 'The HomeMetrics team',
};

export const TeamMobile = {
    source: require('./Team-Mobile.jpg'),
    alt: 'The HomeMetrics team',
}

export const Works = {
    source: require('./Works.jpg'),
    alt: 'A neighborhood map',
};