import React from 'react';
import { Image } from '../common/Image';
import styled from 'styled-components';
import { whitePrimary } from '../../img/logos'

const FooterImage = styled(Image)`
    width: 8em;
    height: auto;
`

export const FooterIcon = () => (
    <FooterImage {...whitePrimary} />
)

export default FooterIcon;