import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../routes';

export const Routes = () => (
    <Switch>
        {routes.filter(
            ({ isHidden }) => (!isHidden)
        ).map(
            ({ exact, path, component }, i) => (
                (<Route exact={exact} path={path} component={component} key={i} />)
            ))}
    </Switch>
)

export default Routes;