import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '../components/common/Buttons';
import { fetchHouses, addHouse, selectHouseStatus, LoadStates } from '../store/houses/housesReducer';
import { Page, House, Unauthenticated } from '../components';
import { HouseCardContainer } from '../components/cards/HouseCard';
import AddHouseForm from '../components/Forms/createhouse';
import { RefreshIcon, AddIcon, CloseIcon } from '../img/icons';
import styled from 'styled-components';
import { Link, Route, useHistory, useRouteMatch, Switch } from 'react-router-dom';
import { requiresAuth } from '../components/hoc'
import { Panel, CardPanel, PanelContent } from '../components/Panels';
import { H1 } from '../components/common/text';

const Unauthorized = () => (<Page><PanelContent><Unauthenticated /></PanelContent></Page>)

const HeaderWrap = styled.div`
    display: flex;
    border-bottom: solid 2px ${props => props.theme.darkAccent};
    padding: 0 1em;
    align-items: center;
    margin: 1em;
`

const HeaderText = styled(H1)`
    margin-right: auto;
    margin-bottom: 0;
`

export const Houses = () => {
    const dispatch = useDispatch();
    let { path, url } = useRouteMatch();
    const history = useHistory();


    const refreshHouses = () => { dispatch(fetchHouses()) }

    const status = useSelector(selectHouseStatus)

    const handleAdd = (houseViewModel) => {
        dispatch(addHouse(houseViewModel))
        history.push(url)
    }

    return (
        <Page>
            <Switch>
                <Route exact path={`${path}/add`}>
                    <PanelContent>
                        <HeaderWrap>
                            <HeaderText>Add a house</HeaderText>
                            <Link to="/houses">
                                <IconButton Icon={CloseIcon} />
                            </Link>
                        </HeaderWrap>
                        <AddHouseForm isLoading={status === LoadStates.ADDING} onSubmit={handleAdd} />
                    </PanelContent>
                </Route>
                <Route exact path={`${path}/:id`} >
                    <PanelContent>
                        <HeaderWrap>
                            <HeaderText>Update a house</HeaderText>
                            <Link to="/houses">
                                <IconButton Icon={CloseIcon} />
                            </Link>
                        </HeaderWrap>
                        <House />
                    </PanelContent>
                </Route>
            </Switch>

            <Panel>
                <HeaderWrap>
                    <HeaderText>My houses</HeaderText>
                    <Link to="/houses/add">
                        <IconButton Icon={AddIcon} />
                    </Link>
                    <IconButton Icon={RefreshIcon} onClick={refreshHouses} />
                </HeaderWrap>
                <CardPanel>
                    <HouseCardContainer />
                </CardPanel>
            </Panel>
        </Page >
    )
}

export default requiresAuth(Unauthorized)(Houses)
