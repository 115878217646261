import React from 'react';
import withCondition from './withCondition';

export const hasLoading = Loading => WrappedComponent => {
    const HasLoading = props => {
        const Component = withCondition(props.isLoading, Loading, WrappedComponent);
        return <Component {...props} />
    }

    HasLoading.displayName = `hasLoading${WrappedComponent.name}`;

    return HasLoading;
}

export default hasLoading;