export const validateEmail = (value) => {
    value = value.toLowerCase();
    //Disabled as this was pulled directly from RFC5322 standards for validating email with regex
    //eslint-disable-next-line
    const rfc5322Regex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    if (!rfc5322Regex.test(value)) {
        return { type: 'emailFormat', message: 'Invalid email format' }
    }
}

export const validateRequired = (value) => {
    if (value === undefined || value === null || value.length === 0)
        return { type: 'isRequired', message: 'Field is Required' }
}

export const validatePhone = (value) => {
    const phoneRegex = /^(\+?\d{1,2}\s*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    if (!phoneRegex.test(value)) {
        return { type: 'phoneFormat', message: 'Invalid phone number' };
    }
}

export const validateHasDigits = (value) => {
    if (!/\d/.test(value))
        return { type: 'noDigits', message: 'Password must contain a digit' }
}

export const validateHasSpecial = (value) => {
    if (!/[!@#$%^&*]/.test(value))
        return { type: 'noSpecials', message: 'Password must contain a special character: !@#$%^&*' }
}

export const validateMinLength = (minLength) => (value) => {
    if (value.length < minLength)
        return { type: 'minLength', message: `Field must contain at least ${minLength} character${minLength === 1 ? '' : 's'}` }
}

export const validateMaxLength = (maxLength) => (value) => {
    if (value.length > maxLength)
        return { type: 'maxLength', message: `Field must contain less than ${maxLength} characters` }
}

export const validateMinValue = (min) => (value) => {
    if (value < min)
        return { type: 'min', message: `Field must be at least ${min}` }
}

export const validateMaxValue = (max) => (value) => {
    if (value > max)
        return { type: 'max', message: `Field must be at most ${max}` }
}

export const validateZip = (zip) => {
    if (!/^[0-9]{5}$/.test(zip)) {
        return { type: 'format', message: 'Invalid zip code' }
    }
}

export const validateState = (state) => {
    if (state.length !== 2) {
        return { type: 'stateFormat', message: 'State must be 2 letter abbreviation' }
    }
}

export const validateAddress = (address) => {
    if (!/\d/.test(address)) {
        return { type: 'addressFormat', message: 'Address requires a house number' };
    }

    if (!/\D/.test(address)) {
        return { type: 'addressFormat', message: 'Address requires a street name' };
    }
}
