import React from 'react';

export const ScreenSizes = {
    PHONE: 0,
    TABLET: 1,
    LAPTOP: 2,
    DESKTOP: 3,
}

export const ScreenWidthCutoffs = {
    PHONE: 600,
    TABLET: 1024,
    LAPTOP: 1280,
}

export const getScreenSize = (width) => {
    let screenSize;

    if (width < ScreenWidthCutoffs.PHONE) {
        screenSize = ScreenSizes.PHONE;
    } else if (width < ScreenWidthCutoffs.TABLET) {
        screenSize = ScreenSizes.TABLET;
    } else if (width < ScreenWidthCutoffs.LAPTOP) {
        screenSize = ScreenSizes.LAPTOP;
    } else {
        screenSize = ScreenSizes.DESKTOP;
    }

    return screenSize;
}

export const ScreenSizeContext = React.createContext({
    size: ScreenSizes.DESKTOP,
})

export default ScreenSizeContext;