import HousePreferencesDataModel from './HousePreferencesDataModel';

export const HouseDataModel = (houseViewModel) => {
    const ret = {
        house_id: houseViewModel.id,
        square_feet: houseViewModel.sqft,
        resident_count: houseViewModel.residentCount,
        bath_count: houseViewModel.bathCount,
        bed_count: houseViewModel.bedCount,
        year: houseViewModel.year,
        address1: houseViewModel.primaryAddress,
        address2: houseViewModel.secondaryAddress,
        city: houseViewModel.city,
        state: houseViewModel.state,
        zipcode: houseViewModel.zipCode
    }

    if (houseViewModel.preferences) {
        ret.house_preferences = HousePreferencesDataModel(houseViewModel.preferences)
    }

    return ret;
}

export default HouseDataModel