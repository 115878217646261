import React from 'react';
import PropTypes from 'prop-types';
import CheckableInput from './CheckableInput';
import styled, { css } from 'styled-components';
import { P } from '../text';

export const Wrapper = styled.div`
    
`

export const CheckBox = styled.div`
    width: 24px;
    height: 24px;
    border: solid 2px gray;
    border-radius: 50%;
    position: relative;
    margin-right: 8px;

    ${props => props.isChecked && css`
        border: solid 2px ${props.theme.primary};
        
        &:hover {
            border: solid 2px ${props.theme.primary};
        }
    `}
`

export const CheckMark = styled.div`
    transition: all 0.125s;
    height: 0;
    width: 0;
    background-color: ${props => props.theme.primary};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;

    ${props => props.isChecked && css`
        height: 80%;
        width: 80%;
    `}
`

const Label = styled(P)`
    padding: 0;
    margin: 0;
    color: ${props => props.theme.darkBase};
`

export const RadioButton = ({ className, displayText, isChecked, ...props }) => (
    < CheckableInput {...props} className={className} >
        <CheckBox isChecked={isChecked}>
            <CheckMark isChecked={isChecked} />
        </CheckBox>
        <Label>{displayText}</Label>
    </CheckableInput >
)

export const propTypes = {
    className: PropTypes.string,
    displayText: PropTypes.string,
    isChecked: PropTypes.bool,
}

RadioButton.propTypes = propTypes;

export default styled(RadioButton)`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &:hover ${CheckBox} {
        border: solid 2px black;
    }
`;
