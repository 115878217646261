import React from 'react';
import { HeroImagePanel, Page } from '../components'
import { H1, H2, P } from '../components/common/text';
import { PanelContent, Panel, SplitContent, Pane } from '../components/Panels';
import { Support } from '../img/headers';
import styled from 'styled-components';
import { CreateUserForm } from '../components/Forms';
import { PrimaryButton } from '../components/common/Buttons';
import { Link } from 'react-router-dom';
import { requiresAuth } from '../components/hoc'


const Title = styled(H1)`
    text-align: left;
    margin: 0;
`

const SubTitle = styled(H2)`
    font-size: 1.25em;
`

const List = styled.ol`
    font-family: ${props => props.theme.baseFont};
    padding: 0;
`

const ListItem = styled.li`
    margin: 1em;
`

const StyledPane = styled(Pane)`
    padding: 0 1.5em;
`

const ListHeader = styled(P)`
    color: #000;
`

const StyledPanelContent = styled(PanelContent)`
    text-align: center;
`

const SignedIn = () => (<><H2>Looks like you already have an account!</H2><Link to={'/houses'}><PrimaryButton>Manage houses</PrimaryButton></Link></>)

const SignedOut = () => (<><H2>Create an Account!</H2><CreateUserForm /></>)

const UserForm = requiresAuth(SignedOut)(SignedIn)

export const SignUp = () => {
    return (
        <Page>
            <HeroImagePanel heroImage={Support}>
                <PanelContent>
                    <Title>The Beta Program</Title>
                    <SubTitle>Try out our MetricsNetwork today</SubTitle>
                </PanelContent>
            </HeroImagePanel>
            <Panel>
                <SplitContent>
                    <StyledPane>
                        <P>Sign-Up below to apply for our beta-program. We have launched our beta program and, over the next few weeks, we will be giving away 15 of our MetricsNetwork to be installed in homes and other properties.</P>
                        <P>Through this program, we hope to gain your feedback on our product. We want to ensure that your voice is heard and reflected in our product to ensure you're receiving the best smart home experience possible!</P>
                    </StyledPane>
                    <StyledPane>
                        <ListHeader><strong>The Process</strong></ListHeader>
                        <List>
                            <ListItem>Sign Up for the Program</ListItem>
                            <ListItem>Winners will be drawn and contacted</ListItem>
                            <ListItem>Time will be scheduled for installation</ListItem>
                            <ListItem>Relax and enjoy a safe and secure home</ListItem>
                            <ListItem>Respond with feedback and your product experience</ListItem>
                        </List>
                    </StyledPane>
                </SplitContent>
            </Panel>

            <Panel>
                <StyledPanelContent>
                    <UserForm />
                </StyledPanelContent>
            </Panel>
        </Page>
    )
}

export default SignUp;