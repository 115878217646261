import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../Image'

export const Icon = ({ icon, className, onClick }) => (
    <Image {...icon} onClick={onClick} className={className} />
);

export const propTypes = {
    icon: PropTypes.shape({ source: PropTypes.string, alt: PropTypes.string }).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
}

export const defaultProps = {
    onClick: () => { },
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
