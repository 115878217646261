import React from 'react';

export const DesktopNavMenu = ({ children }) => {
    return (
        <>
            {children}
        </>
    );
}

export default DesktopNavMenu;