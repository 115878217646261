import React, { useState } from 'react';
import { ModalDrawer } from '../common/Drawer'
import styled from 'styled-components';
import { MenuIcon, MenuCloseIcon } from '../../img/icons';

const HamburgerButton = styled(MenuIcon)`
    fill: #fff;
    height: 36px;
    width: auto;
    filter: drop-shadow(1px 1px 2px #000);
    cursor: pointer
`

const CloseButton = styled(MenuCloseIcon)`
    fill: #e2e2e2;
    height: 36px;
    width: auto;
    margin: 16px 28px 24px auto;
    cursor: pointer;
`

export const MobileNavMenu = ({ children, isDrawerOpen, className }) => {
    const [drawerStatus, setDrawerStatus] = useState(isDrawerOpen);
    const handleClick = (e) => {
        e.stopPropagation(e);
        setDrawerStatus(true);
    }
    window.addEventListener('click', () => { setDrawerStatus(false) });

    return (
        <>
            <HamburgerButton className={className} onClick={handleClick} />
            <ModalDrawer isOpen={drawerStatus} side='left'>
                <CloseButton onClick={() => setDrawerStatus()}>
                    <i className={'fa fa-close'}></i>
                </CloseButton>
                {children}
            </ModalDrawer >
        </>
    );
}

export default MobileNavMenu;