export const blackIcon = {
    source: require('./Black-Icon.svg'),
    alt: 'The HomeMetrics Icon',
};

export const whiteIcon = {
    source: require('./White-Icon.svg'),
    alt: 'The HomeMetrics Icon',
};

export const blackPrimary = {
    source: require('./Black-Primary.svg'),
    alt: 'The HomeMetrics Logo',
};

export const blackSecondary = {
    source: require('./Black-Secondary.svg'),
    alt: 'The HomeMetrics Logo',
};

export const whitePrimary = {
    source: require('./White-Primary.svg'),
    alt: 'The HomeMetrics Logo',
};

export const whiteSecondary = {
    source: require('./White-Secondary.svg'),
    alt: 'The HomeMetrics Logo',
};