import UserViewModel from './models/UserViewModel'
import UserDataModel from './models/UserDataModel'

export const signIn = async (username, password) => {
    const data = `${encodeURIComponent('username')}=${encodeURIComponent(username)}&${encodeURIComponent('password')}=${encodeURIComponent(password)}`

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data
    })

    const result = await response.json()

    if (response.status !== 200) {
        return { isValid: false, message: result.detail }
    }

    return { isValid: true, data: result.access_token }
}

export const fetchUser = async jwt => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/me/model`, { headers: { Authorization: `Bearer ${jwt}` } })

    const result = await response.json()

    if (response.status !== 200) {
        return { isValid: false, message: result.detail }
    }

    return { isValid: true, data: dataToModel(UserViewModel, result) }
}

export const createUser = async (user) => {
    const body = dataToModel(UserDataModel, user)

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/me/model`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })

    const result = await response.json()

    if (response.status !== 200) {
        return { isValid: false, message: result.detail }
    }


    return { isValid: true, data: dataToModel(UserViewModel, result) }
}

export const updateUser = async (jwt, userViewModel) => {
    const body = dataToModel(UserDataModel, userViewModel)

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/me`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(body)
    })

    const result = await response.json()

    if (response.status !== 200) {
        return { isValid: false, message: result.detail }
    }

    return { isValid: true, data: dataToModel(UserViewModel, result) }
}
const dataToModel = (Model, data) => {
    return Array.isArray(data) ? data.map(d => new Model(d)) : new Model(data)
}