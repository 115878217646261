import React from 'react';
import ProductData from '../data/products';
import { ProductList } from '../components/cards';
import { Panel } from '../components/Panels';
import { Page } from '../components';

export const Products = () => {

    return (
        <Page>
            <Panel>
                <ProductList data={ProductData} useResponsive={true} />
            </Panel>
        </Page>
    )
}

export default Products;