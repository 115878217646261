import HousePreferencesViewModel from './HousePreferencesViewModel'

export const HouseViewModel = (houseDataModel) => {
    const ret = {
        id: houseDataModel.house_id,
        sqft: houseDataModel.square_feet,
        residentCount: houseDataModel.resident_count,
        bathCount: houseDataModel.bath_count,
        bedCount: houseDataModel.bed_count,
        year: houseDataModel.year,
        primaryAddress: houseDataModel.address1,
        secondaryAddress: houseDataModel.address2,
        city: houseDataModel.city,
        state: houseDataModel.state,
        zipCode: houseDataModel.zipcode,
    }

    if (houseDataModel.house_preferences) {
        ret.preferences = HousePreferencesViewModel(houseDataModel.house_preferences)
    }

    return ret;
}


export default HouseViewModel
