import React, { useState } from 'react';
import TextInput from './TextInput';
import { visible, hidden } from '../../../img/icons';

export const PasswordInput = ({ iconSource, ...props }) => {
    const [isVisible, setVisible] = useState(false);

    return (
        <TextInput
            {...props}
            onIconClick={() => { setVisible(!isVisible) }}
            icon={isVisible ? visible : hidden}
            type={isVisible ? 'text' : 'password'}
        />
    );
}

export default PasswordInput
