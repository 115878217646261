import React from 'react';
import { PanelContent } from '../Base/PanelContent'
import styled from 'styled-components'

const SplitPanelContent = styled(PanelContent)`
    display: flex;
    flex-wrap: wrap;
`

export const SplitContent = ({ className, style, children }) => {
    return (
        <SplitPanelContent className={className} style={style}>{children}</SplitPanelContent>
    );
}

export default SplitContent;