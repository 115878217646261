import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hasLoading } from './hoc';
import { useParams } from 'react-router-dom';
import { AddHouseForm } from './Forms';
import { Loader } from './common/Loader';
import { H2 } from './common/text';
import { LoadStates, selectedHouse, houseFetchStatus, fetchHouse, updateHouse, selectHouseId } from '../store/houses/housesReducer';
import styled from 'styled-components';

const CenterWrap = styled.div`
    text-align: center;
`

const Loading = () => (<CenterWrap><H2>Loading Houses...</H2><Loader /></CenterWrap>)

const HouseForm = hasLoading(Loading)(AddHouseForm);

export const House = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const houseId = useSelector(selectHouseId)
    const house = useSelector(selectedHouse)
    const status = useSelector(houseFetchStatus)

    useEffect(() => {
        if (status === LoadStates.UNFETCHED) {
            dispatch(fetchHouse(id))
        }

        // Required to ignore the warning for != as Redux returns its own unique immutable object type
        // which will always equate to not equal to on !== for id !== houseId
        // eslint-disable-next-line
        if (status === LoadStates.IDLE && id != houseId) {
            dispatch(fetchHouse(id))
        }
    }, [houseId, status, id, dispatch])


    const handleSubmit = (house) => {
        dispatch(updateHouse({ id: house.id, house }))

    }

    return <HouseForm onSubmit={handleSubmit} {...house} isLoading={status === LoadStates.FETCHING} />;
}


export default House;