import React, { memo } from "react";
import PropTypes from 'prop-types'

export const Button = ({ style, className, onClick, disabled, href, children, target }) => {
    if (href) {
        return (
            <a href={href} target={target}>
                <button
                    style={style}
                    className={className}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {children}
                </button>
            </a>
        );
    } else {
        return (
            <button
                style={style}
                className={className}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }
};

export const propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
}

export const defaultProps = {
    onClick: () => { },
    target: '_blank',
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default memo(Button)