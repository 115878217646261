import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../hooks';
import { PrimaryDrawer } from '../common/Drawer';
import { Form } from '../common/form';
import { TextInput } from '../common/Inputs';
import { PasswordInput } from '../common/Inputs';
import { H2 } from '../common/text';
import { CloseIcon } from '../../img/icons';
import { IconButton, SecondaryButton } from '../common/Buttons';
import styled, { css } from 'styled-components';
import { validateRequired } from '../../utils/validators'

import { signIn, selectStatus, selectError, LoadStates } from '../../store/user/userReducer'

const FormTitle = styled(H2)`
    color: ${props => props.theme.darkBase};
`

export const SignInForm = styled(Form)`
    margin: 0 1em;
`

export const CloseButton = styled(IconButton)`
    margin-left: auto;
`

const validators = {
    username: [validateRequired],
    password: [validateRequired]
}

export const SignInDrawer = ({ isOpen, className, onClose }) => {
    const dispatch = useDispatch()

    const [username, setUsername, userErrors, validateUsername] = useInput('', validators.username);
    const [password, setPassword, passwordErrors, validatePassword] = useInput('', validators.password);
    const [errors, setErrors] = useState([])

    const handleSubmit = (email, Password) => {
        let isValid = validateUsername()
        isValid &= validatePassword()

        if (isValid)
            dispatch(signIn({ email, password }))

    }
    const status = useSelector(selectStatus)
    const submitErrors = useSelector(selectError)

    useEffect(() => {
        if (status === LoadStates.ERROR) {
            setErrors([{ type: 'submit', message: submitErrors }])
        }
    }, [status, submitErrors])


    return (
        <PrimaryDrawer side={'right'} isOpen={isOpen} className={className}>
            <CloseButton Icon={CloseIcon} onClick={onClose} />
            <SignInForm
                errors={errors}
                onSubmit={() => handleSubmit(username, password)}
                isLoading={status === LoadStates.FETCHING}
                submitText='Sign In'
            >
                <FormTitle>Sign In</FormTitle>
                <TextInput label="Email" placeholder="Email" autoComplete="username" value={username} onValueChange={setUsername} errors={userErrors} />
                <PasswordInput label="Password:" placeholder="Password" autoComplete="current-password" value={password} onValueChange={setPassword} errors={passwordErrors} />
            </SignInForm>
            <SecondaryButton>Create Account</SecondaryButton>
        </PrimaryDrawer>
    )
}


export default styled(SignInDrawer)`
    background-color: #fff;
    align-items: center;

    ${props => props.isOpen && css`
        border-left: solid 2px ${props => props.theme.darkAccent};
    `}

`;